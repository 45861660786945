import React from 'react'
import { Anchor, P } from 'components'

import './index.scss'

const TOSLinks: React.FC = () => {
  return (
    <P classes="lh-1 mg-btm--lg" centered>
      <span className="TOSLinks">
        I agree to all of AutoGive’s{' '}
        <Anchor url="https://www.autogive.com/terms" openInNewTab trusted>
          terms &amp; <br/> conditions
        </Anchor>
        ,{' '}
        <Anchor url="https://www.autogive.com/terms#fees" openInNewTab trusted>
          fees
        </Anchor>
        , and{' '}
        <Anchor url="https://www.autogive.com/terms#privacy-policy" openInNewTab trusted>
          privacy policy
        </Anchor>
        .
      </span>
    </P>
  )
}

export default TOSLinks
