import React, { useState } from 'react'

import {
  Section,
  TextArea,
  Time,
  UserText,
} from 'components'
import { pluralize, sort } from 'helpers'
import { useApiQuery, useFormField, useReloadApiQuery } from 'hooks'
import { postData } from 'helpers/post-data'

import Button from './Button'

import './Messages.css'

const messagePageSize = 3

interface Props {
  groupId: GroupId
}

const Messages: React.FC<Props> = ({ groupId }) => {
  const [isPostBoxOpen, setIsPostBoxOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const message = useFormField('')

  const [displayLimit, setDisplayLimit] = useState(messagePageSize)
  const messages = useApiQuery('/api/group/messages', { groupId })
  const reloadMessages = useReloadApiQuery('/api/group/messages', { groupId })

  const permissions = useApiQuery('/api/group/me', { groupId })

  let loading = false

  const postMessage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsLoading(true)

    await postData('/api/group/message/create', { groupId, message })
    reloadMessages()

    setIsPostBoxOpen(false)
    setIsLoading(false)
    message.setValue('')
  }

  if (!messages || !permissions) {
    return null
  }

  const showMore = () => setDisplayLimit(currentLimit => Math.min(messages.length, currentLimit + messagePageSize))
  const showLess = () => setDisplayLimit(currentLimit => Math.max(1, currentLimit - messagePageSize))

  const canShowMore = displayLimit < messages.length
  const canShowLess = displayLimit > 1 && messages.length > 1

  const trailingButton = isPostBoxOpen
    ? <Button text="Close" onClick={() => setIsPostBoxOpen(false)} />
    : <Button text="Post New Message!" onClick={() => setIsPostBoxOpen(true)} />

  return (
    <Section
      id="Messages"
      headerLeading={<h3>{messages.length} {pluralize('Message', messages.length)}</h3>}
      headerTrailing={permissions.permissions.postMessages && trailingButton}
      footerLeading={canShowLess && <Button onClick={showLess} text="Show Less" minimal />}
      footerTrailing={canShowMore && <Button onClick={showMore} text="Show More" minimal />}
    >
      {isPostBoxOpen && <>
        <TextArea binding={message} placeholder="Hey everyone..." />
        <Button text="Post to Group" onClick={postMessage} intent="primary" size="large" loading={isLoading} />
      </>}
      {messages.sort(sort.createdNewestFirst).slice(0, displayLimit).map(message => (<article key={message.id}>
        <UserText text={message.text} />
        <footer>
          <cite>by {message.authorName} (<span style={{textTransform: 'capitalize'}}>{message.role}</span>)</cite>
          <small>posted <Time unixtimeSeconds={message.createdAt} relative /></small>
        </footer>
        <hr />
      </article>
      ))}
    </Section>
  )
}

export default Messages
