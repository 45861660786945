import React from 'react'

import { Time } from 'components'
import { filter } from 'helpers'
import { useApiQuery } from 'hooks'
import { builder } from 'routes'

interface Props {
  groupId: GroupId
  createNew?: boolean
  to?: string
}
const GroupRow: React.FC<Props> = ({ groupId, createNew, to }) => {
  const group = useApiQuery('/api/group/entity', { groupId })
  const roles = useApiQuery('/api/group/me', { groupId })
  const memberships = useApiQuery('/api/group/members', { groupId })
  const sub = useApiQuery('/api/group/myActiveSubscription', { groupId })

  if (!group || !roles || !memberships) {
    return <span></span>
  }

  let roleText = 'Member'
  if (roles.roles.isOrganizer) {
    roleText = 'Organizer'
  } else if (roles.roles.isRecipient) {
    roleText = 'Recipient'
  }

  const name = group.name ? group.name : group.recipientFullName
  const donorCount = memberships.filter(m => filter.money.nonZeroOrNull(m.amount)).length

  return (
    <div>
      {createNew && <a href={builder.group.create} className="groupRowWrap">
        <h4 className="groupRowWrap--create">Create New Group! <span>+</span></h4>
      </a>}

      {!createNew && <a href={builder.group.view(groupId)} className="groupRowWrap">
        <div><h4>{name} </h4> ({roleText})</div>
        <div className="groupRowWrap--inner">
          <div>
            <span>Organizer:</span> {group.organizerFullName}
          </div>
          <div>
            <span>Recipient:</span> {group.recipientFullName}
          </div>
        </div>

        <div className="groupRowWrap--inner">
          <div>
            <span>Created:</span> <Time unixtimeSeconds={group.createdAt} />
          </div>
          <div>
            <span>Donations:</span> {donorCount}
          </div>
        </div>

        {sub?.id &&
          <div style={{marginTop: 6}}>
            <a href={builder.group.changeSubscription(groupId)} className="Link bold">Edit Payment</a>
          </div>        
        }
      </a>
    }
    </div>
  )
}

export default GroupRow
