import React, { useState }  from 'react'
import { Token } from '@stripe/stripe-js'

import {
  AnchorButton,
  Button,
  Form,
  Heading,
  KeyValueList,
  LoaderHeart,
  P,
} from 'components'
import { readError } from 'helpers'
import { useAppWideState } from 'hooks'

interface Props {
  nextPage: Thunk<void>
  prevPage: Thunk<void>
  submit: Thunk<Promise<void>>
  token: Token
}
const ConfirmBank: React.FC<Props> = ({ nextPage, prevPage, submit, token }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showError } = useAppWideState()
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)
    try {
      await submit()
      nextPage()
    } catch (error) {
      console.error(error)
      showError(readError(error, "There was an error processing your request. Please try again shortly; if the issue persists, please contact customer support."))
    } finally {
      setIsSubmitting(false)
    }
  }

  if (!token.bank_account) {
    throw new Error('Bank account token missing from previous page')
  }

  const mask = (suffix: string): string => `········${suffix}`

  if (isSubmitting) {
    return <LoaderHeart intent="kyc" />
  }
  return <Form onSubmit={onSubmit}>
    <Heading>Confirm Info</Heading>
    <P>Please double-check all the information below...</P>
    <KeyValueList data={{
      'Routing Number': token.bank_account.routing_number,
      'Account Number': mask(token.bank_account.last4),
      'Bank Name': token.bank_account.bank_name,
    }} />

    <Button text="Add Bank" type="submit" />

    <P centered>
      <AnchorButton onClick={prevPage} color="#979797">Go Back</AnchorButton>
    </P>
  </Form>
}

export default ConfirmBank
