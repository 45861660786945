// TODO: number >= 0
const pluralize = (singular: string, count: number, plural: string|null = null): string => {
  if (count === 1) {
    return singular
  }
  if (plural !== null) {
    return plural
  }
  return singular + 's'
}

export default pluralize
