import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Loader } from 'components'
import { NavigationTarget } from 'data'
import { useApiQuery, useAppWideState, useFormField, usePageFlow, useRedirect } from 'hooks'
import { builder, groupIdInUrl } from 'routes'

import BaseLayout from 'blocks/layout/base-layout';
import { postData } from 'helpers/post-data'

import Landing from './Landing'
import Register from './Register'

enum Page {
  Landing = '',
  Register = 'register',
}

interface Props {
  groupId: GroupId
}
const RecipientLanding: React.FC<Props> = ({ groupId }) => {
  const { isSignedIn } = useAppWideState()
  const [queryString] = useSearchParams()
  const redirect = useRedirect()

  const [page, updatePage] = usePageFlow<Page>(Page.Landing)
  // Avoid mid-submit redirecting
  const [isBecomeRecipientRunning, setIsBecomeRecipientRunning] = useState(false)

  const token = queryString.get('token') ?? ''

  const onboarding = useApiQuery('/api/group/recipientOnboarding', { groupId, token })

  const name = useFormField('')
  const email = useFormField('')
  const phone = useFormField('')

  // Prefill form fields w/ API data when it arrives
  const nameSetValue = name.setValue
  const emailSetValue = email.setValue
  const phoneSetValue = phone.setValue
  useEffect(() => {
    if (page === Page.Landing) {
      postData('/api/analytics/track', {
        funnel: "RECIPIENT_CLAIM",
        event: "APP_RC_RECIPIENT_CLAIM",
        url: window.location.href,
        groupId,
      })
    }
  }, [page, groupId])
  useEffect(() => {
    if (!onboarding || !onboarding.isAvailable) {
      return
    }
    nameSetValue(onboarding.recipientName)
    emailSetValue(onboarding.recipientEmail)
    phoneSetValue(onboarding.recipientPhone ?? '')
  }, [nameSetValue, emailSetValue, phoneSetValue, onboarding])

  const becomeRecipient = async () => {
    setIsBecomeRecipientRunning(true)
    await postData('/api/group/becomeRecipient', { groupId, token })
    redirect(new NavigationTarget({
      url: builder.group.kyc(groupId),
    }))
    setIsBecomeRecipientRunning(false)
  }

  useEffect(() => {
    // If group is already claimed, send to group view page
    if (onboarding && !onboarding.isAvailable && !isBecomeRecipientRunning) {
      redirect(new NavigationTarget({
        url: builder.group.view(groupId),
      }))
    }
  }, [onboarding, groupId, redirect, isBecomeRecipientRunning])

  if (!onboarding) {
    return <Loader />
  }

  if (page === Page.Landing) {
    let nextPage: AsyncThunk<void>
    if (isSignedIn) {
      nextPage = becomeRecipient
    } else {
      nextPage = async () => updatePage(Page.Register)
    }
    return <Landing groupId={groupId} nextPage={nextPage} />
  } else if (page === Page.Register) {
    return <Register
      groupId={groupId}
      token={token}
      email={email}
      phone={phone}
      name={name}
    />
  }
  throw new Error('Bad page state')
}

const Wrapper: React.FC<Props> = ({ groupId }) => {
  return (
    <BaseLayout>
      <RecipientLanding groupId={groupId} />
    </BaseLayout>
  )
}
export default groupIdInUrl(Wrapper)
