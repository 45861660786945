import React from 'react'

import { TextField } from 'components'

interface Props extends FormFieldProps<string> {
  intent: 'kyc' | 'recipient' | 'self'
}
const NameField: React.FC<Props> = ({
  binding,
  inline,
  intent,
  name,
  required,
}) => {
  let placeholder: string
  let validationMessage: string
  let autoComplete: InputAutocomplete
  if (intent === 'recipient') {
    autoComplete = 'off'
    placeholder = 'Enter Their Full Name'
    validationMessage = 'Recipient name is required'
  } else if (intent === 'self') {
    autoComplete = 'name'
    placeholder = 'Your Full Name'
    validationMessage = 'Full name is required'
  } else if (intent === 'kyc') {
    autoComplete = 'name'
    placeholder = 'Your Full Legal Name'
    validationMessage = 'Full name is required'
  } else {
    throw new Error('Unhandled intent')
  }
  return (
    <TextField
      {...{ autoComplete, binding, inline, name, placeholder, required, validationMessage }}
    />
  )
}

export default NameField
