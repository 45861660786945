export * as Money from './Money'
export { default as NavigationTarget } from './NavigationTarget';

export const timings = {
  refundUnapprovedMember: '30 days',
}

export const constantStrings = {
  inviteOthersAfter: 'You can preview your group & invite others on the next step.',
  willSendSmsCode: 'We’ll text you a code to ensure secure access to your group.',
  usOnlyNote: 'A US SSN or Tax Identification Number is required to receive payments.',
}
