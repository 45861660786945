import React from 'react'
import classNames from 'classnames'

import {
  List,
  Icon,
  Money,
  Time,
} from 'components'

import PhoneInfo from './PhoneInfo'


interface Props {
  membership: Api.Entity.Membership
  selected: boolean
  setSelected: (selected: boolean) => void
}
const ApprovalRow: React.FC<Props> = ({ membership, selected, setSelected }) => {
  const { createdAt, amount, phone, memberFullName } = membership
  return (
    <List.Item>
      <div className={classNames('MemberToApproveRow', { selected, unselected: !selected })} onClick={() => setSelected(!selected)}>
        <Icon icon={selected ? 'checked' : 'unchecked' } />
        <div className="MemberInfo">
          <Time unixtimeSeconds={createdAt} />
          <br />
          {/* Organizer should always get these fields */}
          <Money amount={amount!} highlighted /> - {memberFullName}
          <br />
          {phone && <PhoneInfo {...phone} />}
        </div>
      </div>
    </List.Item>
  )
}

export default ApprovalRow
