import React from 'react'

import {} from 'components'
import { useApiQuery } from 'hooks'

// Images from https://www.dreamstale.com/free-download-payment-method-vector-icons/
// which was suggested https://support.stripe.com/questions/where-to-find-logos-for-accepted-credit-card-types
import amex_d from './amex_d.png'
import amex_l from './amex_l.png'
import disc_d from './disc_d.png'
import disc_l from './disc_l.png'
import mast_d from './mast_d.png'
import mast_l from './mast_l.png'
import visa_d from './visa_d.png'
import visa_l from './visa_l.png'

const CardIcon: React.FC<{ type: CardBrand, color?: boolean }> = ({ type, color }) => {
  let imgSrc: string
  switch (type) {
    case 'amex':
      imgSrc = color ? amex_d : amex_l
    break
    case 'discover':
      imgSrc = color ? disc_d : disc_l
    break
    case 'mastercard':
      imgSrc = color ? mast_d : mast_l
    break
    case 'visa':
      imgSrc = color ? visa_d : visa_l
    break
  }
  // source is 128x80, cut in half. might be good for hidpi trickery?
  return <img src={imgSrc} alt={type} title={type} height={64} width={40} />
}

interface Props {
  paymentMethodId: PaymentMethodId
}
const CardInfo: React.FC<Props> = ({ paymentMethodId }) => {
  const pmInfo = useApiQuery('/api/paymentMethod/entity', { paymentMethodId })
  if (!pmInfo) {
    return null
  }
  const { last4, type, expiration } = pmInfo
  return (
    <span className="CardInfo">
      <CardIcon type={type} />
      {' '}&middot;&middot;&middot;&middot; {last4}
      {' '}
      (expires {expiration.month}/{expiration.year})
    </span>
  )
}

export default CardInfo
