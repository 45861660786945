const password = (value: string): boolean => {
  if (value.length < 8) {
    return false
  }
  if (!value.match(/[A-z]/)) {
    return false
  }
  if (!value.match(/[0-9]/)) {
    return false
  }
  const symbols = [
    '`', '~',
    '!', '@', '#', '$', '%', '^', '&', '*', '(', ')',
    '_', '+', '-', '=',
    '{', '}', '|', '[', ']', '\\',
    ';', '\'', ':', '"',
    '<', '>', '?', ',', '.', '/',
  ]

  for (const sym of symbols) {
    if (value.includes(sym)) {
      return true
    }
  }
  return false
}

const validate = {
  password,
}

export default validate
