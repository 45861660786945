import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import Navigation from './Navigation'

const NavMenu: React.FC = () => {
  const { groupId } = useParams()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Toggle {...{ isOpen, setIsOpen }} />
      {isOpen && (
        <>
          <div id="MenuCloser" onClick={() => setIsOpen(false)} />
          <Navigation groupId={groupId as GroupId|undefined} />
        </>
      )}
    </>
  )
}


interface ToggleProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}
const Toggle: React.FC<ToggleProps> = ({ isOpen, setIsOpen }) => {
  return (
    <button
      type="button"
      className={`${isOpen ? 'isOpen' : 'isClosed'}`}
      onClick={() => setIsOpen(!isOpen)}
    >
      {/*<img src={menuClosed} alt="Menu Icon" />*/}
      <div className="menu-icon"></div>
    </button>
  )
}


export default NavMenu
