import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import flagsmith from 'flagsmith'
// import { FlagsmithProvider } from 'flagsmith/react'
import { datadogLogs } from '@datadog/browser-logs'

import App from './App'
import * as config from 'config'
import reportWebVitals from 'reportWebVitals'
import 'styles/main.scss'

import { consumeImpersonationToken } from 'contexts/appWideState'
import { FlagsmithProvider } from 'contexts/flagsmith'

// NOTE: this is done super early in the application lifecycle in an attempt to
// do this check before any third-party code has loaded. Even though the tokens
// for exchange are functional for a few seconds only, we don't want to leak
// them externally if at all possible. This is also why it's in the window hash
// rather than the URL or query string: it's not sent to the server and won't
// show up in access logs.
if (window.location.pathname === '/impersonate') {
  const fragment = window.location.hash.substring(1)
  consumeImpersonationToken(fragment)
} // wrap rest of app render in else block?? effective early return

// https://docs.datadoghq.com/logs/log_collection/javascript/
// Future: gate this to specific environments?
if (config.DATADOG_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: config.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'frontend', // must match sourcemap upload in docker build
    env: config.NODE_ENV, // must match one of the BE env names (development/staging/production)
    version: config.GIT_COMMIT,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    forwardReports: 'all',
    sessionSampleRate: 100,
    telemetrySampleRate: 0, // DD self-monitoring
    // beforeSend ~ hook to scrub sensitive data
  })
} else {
  console.warn('REACT_APP_DATADOG_CLIENT_TOKEN envvar is not set - Datadog logging disabled')
}

const queryClient = new QueryClient()

const container = document.getElementById('root')
if (!container) {
  throw new Error('Root DOM element is missing')
}
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <FlagsmithProvider
          environmentID={config.FLAGSMITH_ENVIRONMENT_ID}
          // Note: intentionally not using defaultFlags here. It doesn't mesh
          // well for fallback to disabled features, only for default _values_,
          // so we had to do a whole adventure to work around. See #1063.
      >
        <Router>
          <App />
        </Router>
      </FlagsmithProvider>
    </QueryClientProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
