import React from 'react'

import './index.css'

/**
 * Styling wrapper for rows in a form.
 */
interface Props {
  children: Children
  label?: React.ReactNode
}
const FormRow: React.FC<Props> = ({
  children,
  label,
}) => {
  return <div className="FormRow">
    {label && <label className="FormRowLabel">{label}</label>}
    <div className="FormRowColumns">
      {children}
    </div>
  </div>
}

export default FormRow
