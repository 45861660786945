import React from 'react'
import { Link as RRLink, useLocation } from 'react-router-dom'

import { builder } from 'routes'

import './index.css'

/**
 * Special-cased Link to maintain history state after signing in. Sadly this
 * duplicates most of that component, but we really want to avoid exposing
 * react-router internals (especially page state) to our broader components.
 */
interface Props {
  id?: string
}
const SignInLink: React.FC<Props> = ({ id }) => {
  const location = useLocation()
  return (
    <RRLink
      className="SignInLink"
      id={id}
      state={{ afterSignIn: location.pathname + location.search + location.hash } as SignInState}
      to={builder.site.signIn}
    >Sign In</RRLink>
  )
}

export default SignInLink
