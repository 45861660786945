import React from 'react'
import { Link as RRLink } from 'react-router-dom'
import classNames from 'classnames'

import { FormRow } from 'components'
import './index.scss'

interface Props {
  active?: boolean
  button?: boolean // Apply button appearance
  children: Children
  minimal?: boolean // Deemphasize appearance
  tab?: boolean
  to: string
  extraClasses?: string
}
/**
 * Single Page Application internal links - wrapper for react-router's
 * component of the same name. This allows consistent centralized styling, and
 * keeps consuming components decoupled from the router.
 */
const Link: React.FC<Props> = ({ active, button, children, minimal, tab, to, extraClasses }) => {
  const inner = <RRLink to={to} className={classNames('Link', { active, button, minimal, tab }, extraClasses )}>{children}</RRLink>
  return (button && !tab) ? <FormRow>{inner}</FormRow> : inner
}

export default Link
