import { useState } from 'react'

const useFormField = <T>(initialValue: T): FormBinding<T>&DefinesOwnJsonEncoding => {
  const [value, setValue] = useState(initialValue)
  const [isValid, setIsValid] = useState(false)
  return {
    value,
    setValue,
    isValid,
    setIsValid,
    toJSON: () => value,
  }
}

export default useFormField
