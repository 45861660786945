import React, { useEffect, useState } from 'react';

import { ContactLink, Heading, Link, List, P } from 'components'
import GroupListSimple from '../../pages/MyGroups/GroupListSimple'
import { builder, mustBeSignedIn } from 'routes'
import { useApiQuery, useAppWideState } from 'hooks'

import BaseLayout from 'blocks/layout/base-layout';

const UserSettings: React.FC = () => {
  // Target non-group-specific settings:
  //
  // - Change Phone
  // - Change Email
  //
  // Not really settings but still useful:
  // - Payment History (all groups)
  // - Subscription List (link to edit them?)

  // const permissions = useApiQuery('/api/group/me', { groupId })
  // const group = useApiQuery('/api/group/entity', { groupId })
  // const members = useApiQuery('/api/group/members', { groupId })


  const groupIds = useApiQuery('/api/group/mine', undefined)
  const { user } = useAppWideState()

  return (
    <BaseLayout showNavigationBackButton showNavigation>
      <Heading>My Settings</Heading>
      Manage your account information below.

      <article>
        <strong>Your Email</strong>
        <br/>
        {user?.email}
        <br/><br/>

        <Link extraClasses="font-semibold" to={builder.user.changePassword}>Change Password</Link>
        <br/><br/>

        <strong>Your Groups</strong>
        {groupIds && groupIds.map(id => <GroupListSimple key={id} groupId={id} />)}        
      </article>

      <P centered>
        <strong>Need help?</strong>
        <br />
        <ContactLink>Contact Us</ContactLink>
      </P>

    </BaseLayout>
  )
}

export default mustBeSignedIn(UserSettings)
