import React, { useState } from 'react'

import {
  AnchorButton,
  Button,
  Heading,
  KeyValueList,
  P,
} from 'components'
import { formatPhoneNumber } from 'helpers'

interface Props {
  email: FormBinding<string>
  goBack: React.MouseEventHandler
  message: FormBinding<string>
  name: FormBinding<string>
  phone: FormBinding<string>
  submit: React.MouseEventHandler
}
const Confirm: React.FC<Props> = ({
  email,
  goBack,
  message,
  name,
  phone,
  submit,
}) => {
  const [submitting, setSubmitting] = useState(false)
  const onClick = (e: React.MouseEvent) => {
    setSubmitting(true)
    submit(e)
  }
  return <>
    <Heading>Confirm Info</Heading>

    <P>Please double-check all of the Recipient's information below...</P>

    <KeyValueList data={{
      Name: name.value,
      Email: email.value,
      Phone: formatPhoneNumber(phone.value),
    }} />

    <Button
      onClick={onClick}
      disabled={submitting}
      text="Invite Recipient"
      type="button"
    />

    <P centered>
      <AnchorButton onClick={goBack}>Go Back</AnchorButton>
    </P>
  </>
}

export default Confirm
