import React from 'react';
import classNames from 'classnames'

import './index.css';

interface Props {
  children: Children
  id?: string
  intent?: Intent
  color?: string
  extraClass?: string
  onClick: React.MouseEventHandler<HTMLAnchorElement>
}
/**
 * A link that behaves like a button.
 */
const AnchorButton: React.FC<Props> = ({ intent = 'none', children, id, onClick, color, extraClass = '' }) => {
  return (
    <a
      id={id}
      role="button"
      className={classNames("AnchorButton", extraClass, {
        'intent-none': intent === 'none',
        'intent-secondary': intent === 'secondary',
        'intent-warning': intent === 'warning'
      })}
      onClick={onClick}
      style={{color}}
    >
      {children}
    </a>
  );
};

export default AnchorButton;
