import React from 'react'
import classNames from 'classnames'

import './index.css'

import checked from 'assets/svg-icons/checkbox-checked.svg'
import clipboard from 'assets/svg-icons/clipboard.svg'
import clock from 'assets/svg-icons/clock.svg'
import currency from 'assets/svg-icons/currency.svg'
import email from 'assets/svg-icons/email.svg'
import lock from 'assets/svg-icons/lock.svg';
import phone from 'assets/svg-icons/phone.svg'
import unchecked from 'assets/svg-icons/checkbox-unchecked.svg'
import user from 'assets/svg-icons/user.svg'

type Props = {
  icon: IconName
  inline?: boolean
  size?: IconSize
  classes?: string
}
const Icon: React.FC<Props> = ({ icon, inline = false, size = 'medium', classes }) => {
  let src
  switch (icon) {
    case 'checked': src = checked; break
    case 'clipboard': src = clipboard; break
    case 'clock': src = clock; break
    case 'currency': src = currency; break
    case 'email': src = email; break
    case 'lock': src = lock; break
    case 'phone': src = phone; break
    case 'unchecked': src = unchecked; break
    case 'user': src = user; break
  }

  return <img
    alt={`${icon} icon`}
    className={classNames("Icon", size, classes, { inline })}
    src={src}
  />
}

export default Icon
