import React, { useEffect, useState } from 'react'

import {
  Button,
  Form,
  Heading,
  KeyValueList,
  Link,
  List,
  Loader,
  P,
  Subhead,
} from 'components'
import { timings } from 'data'
import { pluralize } from 'helpers'
import { useApiQuery, usePageFlow, useReloadApiQuery } from 'hooks'
import { builder, groupIdInUrl, mustBeSignedIn } from 'routes'

import BaseLayout from 'blocks/layout/base-layout';
import { postData } from 'helpers/post-data'

import ApprovalRow from './ApprovalRow'
import AfterApprovedPage from './AfterApprovedPage'
import './index.css'

enum Page {
  Selecting = '',
  PostSelection = 'done',
}

interface Props {
  groupId: GroupId
}
const AllowMembersToGroup: React.FC<Props> = ({ groupId }) => {
  const group = useApiQuery('/api/group/entity', { groupId })
  const members = useApiQuery('/api/group/members', { groupId })
  const reloadMembers = useReloadApiQuery('/api/group/members', { groupId })
  // A Set would be better here but it gets fiddly with state tracking
  const [selected, setSelected] = useState<MembershipId[]>([])
  const [page, updatePage] = usePageFlow<Page>(Page.Selecting)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    postData('/api/analytics/track', { funnel: "MEMBER_ALLOW", event: "APP_MA_ALLOW_MEMBERS", url: window.location.href, groupId: groupId })
  }, [groupId])

  useEffect(() => {
    if (members) {
      const membersToApprove = members.filter(member => !member.isApproved)
      setSelected(membersToApprove.map(member => member.id))
    }
  }, [members])  

  if (!group || !members) {
    return <Loader />
  }

  const membersToApprove = members.filter(member => !member.isApproved)

  if (membersToApprove.length === 0) {
    return (
      <>
        <Heading>Allow Members</Heading>
        <P>There are no members waiting for approval.</P>

        <br />
        <Link to={builder.group.view(groupId)} button>Continue to Group</Link>
      </>
    )
  }

  const backToSelectionScreen = () => {
    // DO NOT clear out the selection on form submission - it will screw up the
    // count on the following page.
    //
    // This is somewhat clumsy, but gets the job done for now.
    setSelected([])
    updatePage(Page.Selecting)
  }

  if (page === Page.PostSelection) {
    return <AfterApprovedPage
      approvedCount={selected.length}
      backToSelectionScreen={backToSelectionScreen}
      groupId={groupId}
      pendingCount={membersToApprove.length - selected.length}
    />
  }

  const makeSetter = (id: MembershipId) => (isSelected: boolean) => {
    setSelected((prev) => {
      if (isSelected) {
        return [...prev, id]
      } else {
        return prev.filter(prevId => prevId !== id)
      }
    })
  }

  const disabled = selected.length === 0 || submitting

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setSubmitting(true)

    try {
      const result = await postData('/api/group/approveMemberships', { groupId, membershipIds: selected })
      reloadMembers()
      updatePage(Page.PostSelection)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div id="AllowMembersToGroup">
      <Form onSubmit={onSubmit}>

        <Heading>Allow Members</Heading>
        <KeyValueList data={{
          Group: group.recipientFullName,
        }} />

        <P>Select which members to allow into your group below...</P>
        <P>Members <span style={{textDecoration: 'underline', fontWeight: 600}}>not allowed</span> after {timings.refundUnapprovedMember} will be refunded.</P>

        <Subhead>{membersToApprove.length} {pluralize('Member', membersToApprove.length)} Pending</Subhead>
        <List>
          {membersToApprove.map((member) => (
            <ApprovalRow membership={member} key={member.id} selected={selected.includes(member.id)} setSelected={makeSetter(member.id)} />
          ))}
        </List>

        <Button type="submit" text={submitting ? "Allowing members..." : "Allow Selected"} disabled={disabled} />

        <P centered>
          <Link to={builder.group.view(groupId)}>Skip to Group</Link>
        </P>
      </Form>
    </div>
  )
}

const Wrapper: React.FC<Props> = ({ groupId }) => {
  return (
    <BaseLayout showNavigationBackButton showNavigation>
      <AllowMembersToGroup groupId={groupId} />
    </BaseLayout>
  )
}

export default mustBeSignedIn(groupIdInUrl(Wrapper))
