import { useQueryClient } from 'react-query';

type AQ = Api.Queries.AnyQuery

type QueryBody<T extends AQ['name']> = Extract<AQ, { name: T }>['body']

/**
 * Returns a callback that will cause a (probably already fetched) query result
 * to be reloaded.
 *
 * See useApiQuery()
 */
const useReloadApiQuery = <T extends AQ['name']>(
  name: T,
  body: QueryBody<T>,
): Thunk<void> => {

  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries([name, body])
  }
}

export default useReloadApiQuery
