import React, { useState } from 'react'
import { Token } from '@stripe/stripe-js'
import { useStripe } from '@stripe/react-stripe-js';

import {
  Anchor,
  AnchorButton,
  Button,
  Form,
  Heading,
  P,
  Subhead,
  TextField,
} from 'components'
import {
  useFormField,
} from 'hooks'

import check from 'assets/images/check-info.svg'

interface Props {
  name: FormBinding<string>
  token: React.MutableRefObject<Token|null>
  nextPage: Thunk<void>
  prevPage: Thunk<void>
}
const EnterBank: React.FC<Props> = ({ name, nextPage, prevPage, token }) => {
  const stripe = useStripe()

  const routing = useFormField('')
  const account = useFormField('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  if (!stripe) { return null }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)
    const response = await stripe.createToken('bank_account', {
      country: 'US',
      currency: 'USD',
      routing_number: routing.value,
      account_number: account.value,
      account_holder_type: 'individual',
      account_holder_name: name.value,
    })
    setIsSubmitting(false)
    if (response.token) {
      token.current = response.token
      nextPage()
    } else {
      console.error('Stripe tokenization error', response.error)
      if (response.error.code === 'account_number_invalid') {
        account.setIsValid(false)
      } else if (response.error.code === 'routing_number_invalid') {
        routing.setIsValid(false)
      }
    }
  }

  const disabled = isSubmitting || !account.isValid || !routing.isValid

  return <Form onSubmit={onSubmit}>
    <Heading>Banking Info</Heading>

    <P>Please provide your banking info to set up weekly deposits.</P>

    <Subhead>Bottom-left of check</Subhead>

    <img
      alt="Indication of where the routing and account number can be found on a paper check."
      src={check}
      style={{ userSelect: 'none'}}
      width="100%"
    />

    <TextField
      binding={routing}
      inputMode="numeric"
      minLength={9}
      maxLength={9}
      pattern="[0-9]{9}"
      placeholder="Routing Number"
      required
      validationMessage="Invalid routing number"
    />

    <TextField
      binding={account}
      inputMode="numeric"
      maxLength={17}
      minLength={3}
      pattern="[0-9]{3,17}"
      placeholder="Account Number"
      required
      validationMessage="Invalid account number"
    />

    <P>Deposits can only be made to checking accounts.</P>

    <Button type="submit" text={isSubmitting ? 'Encrypting...' : 'Next'} disabled={disabled} />

    <P>
      By continuing, you agree to the&nbsp;
      <Anchor
        url="https://stripe.com/US/legal/connect-account"
        openInNewTab
      >Connected Account Agreement</Anchor>
      , and you certify that the information you have provided is complete and correct.
    </P>

    <P centered>
      <AnchorButton onClick={prevPage} color="#979797">Go Back</AnchorButton>
    </P>
  </Form>
}

export default EnterBank
