// Pull values from the runtime template at public/index.html
// (there's an `envsubst` trick that runs at deploy, .env works locally)
// @ts-ignore
const INJECTED_ENV_DATA: DictOf<string> = window.ENV || {}
const BUILDTIME_ENV_DATA = process.env || {} as DictOf<string>

// Follow the REACT_APP_ prefix convention
const SANITIZED_INJECTED_ENV_DATA = Object.keys(INJECTED_ENV_DATA)
  .filter((key) => key === 'NODE_ENV' || key.startsWith('REACT_APP_'))
  .reduce((obj, key) => {
    const value = INJECTED_ENV_DATA[key]
    // Remove blanks or non-substituted values
    if (value !== '' && !value.startsWith('$')) {
      // @ts-ignore
      obj[key] = value
    }
    return obj
  }, {})

const combined: DictOf<string> = {
  ...BUILDTIME_ENV_DATA,
  ...SANITIZED_INJECTED_ENV_DATA,
}

export const REACT_APP_GOOGLE_CLIENT_ID = combined.REACT_APP_GOOGLE_CLIENT_ID
export const REACT_APP_STRIPE_KEY = combined.REACT_APP_STRIPE_KEY
export const REACT_APP_SEGMENT_API_KEY = combined.REACT_APP_SEGMENT_API_KEY
export const DATADOG_CLIENT_TOKEN = combined.REACT_APP_DATADOG_CLIENT_TOKEN
export const FLAGSMITH_ENVIRONMENT_ID = combined.REACT_APP_FLAGSMITH_ENVIRONMENT_ID
export const NODE_ENV = combined.NODE_ENV as 'production' | 'staging' | 'development'
export const GIT_COMMIT = combined.REACT_APP_GIT_COMMIT

export const API_HOST = combined.REACT_APP_API_HOST || window.location.origin

// FIXME: pull values from backend
// https://app.asana.com/0/1204428216661834/1204437469822726/f
export const DEFAULT_CURRENCY: Currency = 'USD'
export const DONATION_LIMIT_MIN: Money = { amount: "500", currency: DEFAULT_CURRENCY }
export const DONATION_LIMIT_MAX: Money = { amount: "100000", currency: DEFAULT_CURRENCY }
console.debug(FLAGSMITH_ENVIRONMENT_ID)
