// We're not doing anything outside of USD yet, but this makes testing the
// components a bit easier.
//
// TS refuses to let me use `Currency` as the dict key :/
const exponents: DictOf<number> = {
  CAD: 2,
  EUR: 2,
  GBP: 2,
  IDR: 2,
  INR: 2,
  JPY: 0,
  USD: 2,
  XTS: 0, // Test
  XXX: 0, // No Currency
}

const zero: Money = { amount: '0', currency: 'XXX' }


/**
 * Takes a Money structure and returns a human-friendly string representation
 *
 * TODO: Intl.NumberFormat ?
 * Deprecate this? The internals are pretty wrong in some locales and it's only
 * used in one place.
 */
const toFormatted = (money: Money, withSymbol = false): string => {
  const exponent = exponents[money.currency]
  const conversionFactor = Math.pow(10, exponent)

  const formatted = (parseInt(money.amount, 10) / conversionFactor).toFixed(exponent);

  if (withSymbol) {
    const symbol = getSymbol(money.currency)
    return `${symbol}${formatted}`
  } else {
    return formatted
  }
}


/**
 * Takes arbitrary user input and intreprets it into a Money structure. Invalid
 * or otherwise unprocessable data may return Money.none.
 * TODO: Improve i18n
 */
const fromUserInput = (text: string, currency: Currency): Money => {
  // Hacky: strip thousands separators. Needs better i18n
  const cleanText = text.replace(new RegExp('\\,', 'g'), '');

  const float = parseFloat(cleanText)
  if (Number.isNaN(float)) {
    return zeroWithCurrency(currency)
  }

  const conversionFactor = Math.pow(10, exponents[currency])
  const fractional = Math.round(float * conversionFactor)

  return { amount: fractional.toString(), currency }
}


/**
 * Gets the symbol for the currency. May NOT be a single character depending.
 */
const getSymbol = (currency: Currency): string => {
  const formatter = new Intl.NumberFormat(undefined, { currency, style: 'currency' })
  const parts = formatter.formatToParts(0)
  const currencyPart = parts.find(part => part.type === 'currency')
  return currencyPart?.value || currency
}

const isSameCurrency = (a: Money, b: Money): boolean => a.currency === b.currency

const createBreakdown = (currency: Currency): Api.Breakdown => ({
  chargedToPaymentMethod: zeroWithCurrency(currency),
  platformFee: zeroWithCurrency(currency),
  processingCost: zeroWithCurrency(currency),
  recipientAmount: zeroWithCurrency(currency),
})

// Utility wrapper for e.g sort comparitors
const compare = (a: Money, b: Money): number => {
  assertSameCurrency(a, b)
  return parseInt(a.amount, 10) - parseInt(b.amount, 10)
}

const isEqual = (a: Money, b: Money): boolean => {
  return compare(a, b) === 0
}

const isGreaterThan = (a: Money, b: Money): boolean => {
  return compare(a, b) > 0
}
const isLessThan = (a: Money, b: Money): boolean => {
  return compare(a, b) < 0
}

const assertSameCurrency = (a: Money, b: Money) => {
  if (!isSameCurrency(a, b)) {
    throw new Error(`Trying to compare two Money types of different currency (${a.currency}, ${b.currency})`)
  }
}

const amountWithCurrency = (amount: number, currency: Currency): Money => ({
  amount: amount.toString(),
  currency,
})
const zeroWithCurrency = (currency: Currency): Money => amountWithCurrency(0, currency)

export {
  amountWithCurrency,
  assertSameCurrency,
  createBreakdown,
  exponents,
  fromUserInput,
  getSymbol,
  isEqual,
  isGreaterThan,
  isLessThan,
  toFormatted,
  zero,
  zeroWithCurrency,
}
