import React from 'react'

import { Heading, Link } from 'components'
import { NODE_ENV } from 'config'
import { useFeatureToggle } from 'hooks'
import { builder } from 'routes'

import './index.css'
import iconHomepageFooter from 'assets/svg-icons/icon-homepage-footer.svg'


/**
 * Internal dependencies
 */
import BaseLayout from 'blocks/layout/base-layout'

const Home = () => {
  const showButtonHere = useFeatureToggle('showCreateGroupLinkOnHomepage')
  const showButtonAnywhere = useFeatureToggle('showCreateGroupLinks')
  switch (NODE_ENV) {
    case 'production':
        window.location.href = 'https://www.autogive.com/'
        return null
    default: // development
      return (
        <BaseLayout showNavigation>
          <div id="Home">
            <Heading>Private, monthly group giving.</Heading>
            <ol>
              <li>Create your group.</li>
              <li>Invite group members.</li>
              <li>
                Collect <strong>monthly</strong> payments for yourself or someone else.
              </li>
            </ol>
            {showButtonHere && showButtonAnywhere && (
              <Link to={builder.group.create} button>Create Your Group</Link>
            )}
            <img src={iconHomepageFooter} alt="People holding gifts" />
          </div>
        </BaseLayout>
      )
  }
}

export default Home
