import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { postData } from 'helpers/post-data'

import {
  AnchorButton,
  Heading,
  LoaderHeart,
  P,
  PaymentForm,
} from 'components'
import { NavigationTarget } from 'data'
import { readError, showConfetti } from 'helpers'
import { useAppWideState, useApiQuery, useRedirect, useReloadApiQuery } from 'hooks'
import { builder } from 'routes'

interface Props {
  groupId: GroupId
}
const ManageInactive: React.FC<Props> = ({ groupId }) => {
  const { showError } = useAppWideState()
  const navigate = useNavigate()
  const group = useApiQuery('/api/group/entity', { groupId })
  const [isPaying, setIsPaying] = useState(false)
  const reloadActiveSub = useReloadApiQuery('/api/group/myActiveSubscription', { groupId })
  const redirect = useRedirect()

  if (!group) {
    return null
  }

  if (isPaying) {
    return <LoaderHeart intent="pay" />
  }

  const onSubmit = async (data: PaymentFormResult) => {
    setIsPaying(true)

    // This should be our simplest payment flow, since you're always
    // authenticated while here.
    const { recipientAmount: amount, paymentMethodId } = data
    try {
      await postData('/api/subscription/create', { amount, groupId, token: paymentMethodId })
      showConfetti()
      reloadActiveSub()
      redirect(new NavigationTarget({
        url: builder.group.view(groupId),
      }))
      return true
    } catch (error) {
      // Bad card or something. Need to clean this up.
      console.error(error)
      showError(readError(error, "Payment failed, please try again"))
      return false
    } finally {
      setIsPaying(false)
    }
  }

  return (<>
    <Heading>Canceled</Heading>

    <P>You previously canceled your payment to {group.recipientFullName}.</P>

    {/* This is a little overbearing at the moment in terms of content, but it's
        by far the most straightforward path to getting the functionality in
        place. We'll tweak the UI a bit later.
      */}
    <PaymentForm
      buttonText="Restart payment"
      collectPayerEmail={false}
      collectPayerName={false}
      collectPayerPhone={false}
      currency={group.defaultCurrency}
      maxAmount={group.donationMax}
      minAmount={group.donationMin}
      title=""
      onSubmit={onSubmit}
    />

    <P centered>
      <AnchorButton onClick={() => navigate(-1)} color="#979797">Go back</AnchorButton>
    </P>
  </>)
}

export default ManageInactive
