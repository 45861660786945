import React from 'react'
import classNames from 'classnames'

import './index.css'

interface Props {
  centered?: boolean
  children: Children
  color?: string
  classes?: string
}
const P: React.FC<Props> = ({ centered, children, color, classes }) => {
  const pClasses = classNames('P', { centered });
  return (
    <p className={`${classes ? classes : ''} ${pClasses}`} style={{color}}>{children}</p>
  )
}

export default P
