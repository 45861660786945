import { NavigateOptions } from 'react-router-dom'
/**
 * This is only to make finding state easier so we can refactor it later. While
 * using URL/history state is workable, it's difficult to track down what can
 * arrive from where. By wrapping new navigation changes in this object, we can
 * grep for use of this class easily & find what combinations exist.
 *
 * Long-term this should probably be removed with a better solution, likely
 * through some application of Redux or similar.
 */
class NavigationTarget {
    public readonly url: string
    public readonly options: NavigateOptions

    constructor({ url, inPlace = false } : { url: string, inPlace?: boolean }) {
        this.url = url
        this.options = {
            replace: inPlace,
        }
    }
}

export default NavigationTarget
