import { useEffect, useState } from 'react'

import { TextField } from 'components'
import { Money } from 'data'
import { filter } from 'helpers'
import { useFormField } from 'hooks'

import CurrencySymbol from './CurrencySymbol'
import makeValidationErrorMessage from './makeValidationErrorMessage'
import './index.scss'

const defaultCurrency = 'USD'

interface Props extends FormFieldProps<Money> {
  max?: Money
  min?: Money
}
const MoneyInput: React.FC<Props> = ({
  binding,
  // Inline intentionally excluded for now
  max,
  min,
  name,
  required,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const currency = binding.value.currency === 'XXX' ? defaultCurrency : binding.value.currency

  // This adds an intermediate layer to convert between Money and string.
  // Display an initial value of zero as an empty string so that it instead
  // shows the placeholder.
  const initialStringValue = filter.money.isZero(binding.value) ? '' : Money.toFormatted(binding.value)
  const stringBinding = useFormField(initialStringValue)
  // Hide errors initially - this ensures they're only displayed in a timely
  // manner (i.e. on blur)
  const [mayShowError, setMayShowError] = useState(false)
  const [amountTooLarge, setAmountTooLarge] = useState(false)

  // Translate string input to Money in real time
  const bindingSetValue = binding.setValue
  const stringBindingSetValue = stringBinding.setValue
  useEffect(() => {
    const newAmount = Money.fromUserInput(stringBinding.value, currency)
    if (!Money.isEqual(newAmount, binding.value)) {
      // Avoid "blipping" the change if it's a no-op - if an upstream component
      // is reacting to the amount changing, this (more or less) debounces the
      // real-time changes from the final onBlur. See first use in #834.
      //
      // This is directional based on the current focus state: if currently
      // focused, push the user input out. If blurred (something else changed
      // the binding amount), pull it in to the string verison.
      if (isFocused) {
        bindingSetValue(newAmount)
      } else {
        stringBindingSetValue(Money.toFormatted(binding.value))
      }
    }
  }, [stringBinding.value, currency, bindingSetValue, stringBindingSetValue, isFocused, binding.value])

  // Push up validity state (also real time), including min/max range
  const bindingSetIsValid = binding.setIsValid
  useEffect(() => {
    // If raw form is bad, do nothing. Mostly covers required
    if (!stringBinding.isValid) {
      bindingSetIsValid(false)
      return
    }

    if (min && Money.isLessThan(binding.value, min)) {
      bindingSetIsValid(false)
    } else if (max && Money.isGreaterThan(binding.value, max)) {
      bindingSetIsValid(false)
    } else {
      bindingSetIsValid(true)
    }
  }, [bindingSetIsValid, binding.value, max, min, stringBinding.isValid])

  // Format and allow showing errors only on blur. This primarily fixes input
  // cursor jank from the managed input changing as you're typing.
  const onBlur = () => {
    setMayShowError(true)
    if (filter.money.isZero(binding.value)) {
      stringBinding.setValue('')
    } else {
      stringBinding.setValue(Money.toFormatted(binding.value))
    }
    setIsFocused(false)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const regex = /^\d*\.?\d{0,1}$/;

    if ((!regex.test(stringBinding.value) || (stringBinding.value.length > 6)) && (e.key !== 'Backspace' && e.key !== 'Delete')) {
      e.preventDefault()
    } 

    // if (stringBinding.value.length > 7) {
    //   setAmountTooLarge(true)
    // }     

    if (e.key === 'Backspace' || e.key === 'Delete') {
      setAmountTooLarge(false)
    }    

    // if ((!regex.test(stringBinding.value) || (!stringBinding.value.includes('.') && stringBinding.value.length > 6)) && (e.key !== 'Backspace' && e.key !== 'Delete')) 
  }  


  const showValidationError = mayShowError && !binding.isValid || amountTooLarge

  return (
    <div className="amountInput--wrap">
      <TextField
      binding={stringBinding}
      errorMessage={showValidationError && makeValidationErrorMessage({ min, max, required })}
      inputMode="decimal"
      name={name}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onFocus={() => setIsFocused(true)}
      placeholder={Money.toFormatted(Money.zeroWithCurrency(currency))}
      required={required}
      type="number"
      classes="font-bold amountInput"
    />
  </div>
  )
}

const PerMonth = () => <span className="MoneyInputPerMonth">per&nbsp;month</span>

export default MoneyInput
