import React, { useState } from 'react'

import {
  Button,
  EmailField,
  Form,
  Heading,
  LoaderHeart,
  NameField,
  P,
  PasswordField,
  PhoneNumberField,
} from 'components'
import { NavigationTarget } from 'data'
import { readError, showConfetti } from 'helpers'
import { useAppWideState, useFormField, useRedirect } from 'hooks'
import { builder } from 'routes'

import { postData } from 'helpers/post-data'

interface Props {
  groupId: GroupId
  token: string
  email: FormBinding<string>
  name: FormBinding<string>
  phone?: FormBinding<string>
}
const Register: React.FC<Props> = ({ email, groupId, name, phone, token }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showError, signIn } = useAppWideState()
  const redirect = useRedirect()

  const password = useFormField('')

  const emailOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)
    try {
      const registration = await postData('/api/auth/register/password', { email, name, password })
      const authToken = registration.data.token
      signIn(authToken)

      await postData('/api/group/becomeRecipient', { groupId, token })
      redirect(new NavigationTarget({
        url: builder.group.kyc(groupId),
      }))

      showConfetti()
    } catch (error) {
      showError(readError(error, 'Claiming group failed, please try again'))
    } finally {
      setIsSubmitting(false)
    }
  }

  if (isSubmitting) {
    return <LoaderHeart intent="new-account" />
  }

  const disabled = !name.isValid || !email.isValid || isSubmitting

  return (
    <Form onSubmit={emailOnSubmit}>
      <Heading>Thank you!</Heading>
      <P>Please finish creating your account below.</P>

      <NameField
        binding={name}
        intent="self"
        required
      />
      <EmailField
        intent="register"
        required
        binding={email}
      />
      <PasswordField
        binding={password}
        type="create"
      />
{/*      <PhoneNumberField
        intent="register"
        required
        binding={phone}
      />*/}

      <P>We'll text you a code to ensure secure access to your group.</P>

      <Button type="submit" text="Next" disabled={disabled} />

    </Form>
  )
}

export default Register
