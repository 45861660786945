import React, { useEffect } from 'react'

import { Heading, Loader } from 'components'
import { NavigationTarget } from 'data'
import { useApiQuery, useFeatureToggle, useRedirect } from 'hooks'
import { builder, mustBeSignedIn } from 'routes'

import './index.scss'
import Group from './Group'
import GroupRow from './GroupRow'
import GroupListItem from './GroupListItem'

import plus from 'assets/images/plus.svg'

import BaseLayout from 'blocks/layout/base-layout';

const MyGroups = () => {
  const groupIds = useApiQuery('/api/group/mine', undefined)
  const showCreateGroupLink = useFeatureToggle('showCreateGroupLinks')
  const redirect = useRedirect()

  useEffect(() => {
    if (groupIds && groupIds.length === 1) {
      redirect(new NavigationTarget({
        url: builder.group.view(groupIds[0]),
        inPlace: true,
      }))
    }
  }, [groupIds, redirect])

  if (!groupIds) {
    return <span></span>
  }

  return (
    <BaseLayout showNavigation>
      <div id="MyGroups">
      <Heading>My Groups</Heading>
         
        <section>
          {groupIds.map(id => <GroupRow key={id} groupId={id} />)}
          {showCreateGroupLink && <GroupRow
            groupId={groupIds[0]}
            createNew={true}
            to={builder.group.create}
          />}
        </section>
        
      </div>
    </BaseLayout>
  )
}

export default mustBeSignedIn(MyGroups)