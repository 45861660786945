import { useEffect, useRef, useState } from 'react'

import {
  Form,
  Heading,
  LoaderHeart,
  Link,
  P,
  PaymentForm,
  Section,
  TextField,
  UserInfoForm,
} from 'components'
import {
  DEFAULT_CURRENCY,
  DONATION_LIMIT_MIN,
  DONATION_LIMIT_MAX,
} from 'config'
import { NavigationTarget, constantStrings } from 'data'
import { readError } from 'helpers'
import { useAppWideState, useFormField, usePageFlow, useRedirect } from 'hooks'
import { builder } from 'routes'

import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'
import '../index.scss'

enum Page {
  Landing = '',
  FinishRegistration = 'finish'
}

const CreateGroupForOther: React.FC = () => {
  const { isSignedIn, showError, signIn } = useAppWideState()
  const [page, updatePage] = usePageFlow<Page>(Page.Landing)
  const redirect = useRedirect()
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false)
  const noop = () => {}

  const email = useFormField('')
  const name = useFormField('')
  const phone = useFormField('')
  const password = useFormField('')
  const recipientName = useFormField('')

  const amount = useRef<Money>()
  const groupId = useRef<GroupId>() // Should prevent creating dupe groups on payment error
  const token = useRef<string>()

  const createAndPay = async (): Promise<boolean> => {
    console.assert(isSignedIn, 'Create and pay not signed in!')
    setIsPaymentProcessing(true)
    try {
      // If we created a group during a previous attempt, don't do it again.
      if (groupId.current === undefined) {
        groupId.current = (await postData('/api/group/create/other', { recipientName })).data.id
      }
    } catch (error) {
      showError(readError(error, 'Could not create group'))
      setIsPaymentProcessing(false)
      return false
    }
    try {
      await postData('/api/subscription/create', {
        amount: amount.current,
        groupId: groupId.current,
        token: token.current,
      })
    } catch (error) {
      showError(readError(error, 'Payment failed, please try again'))
      setIsPaymentProcessing(false)
      // Back to the payment screen
      updatePage(Page.Landing)
      return false
    }

    redirect(new NavigationTarget({ url: builder.group.setPublicMessage(groupId.current!) }))
    return true
  }

  const onPaymentSubmit = async (data: PaymentFormResult): Promise<boolean> => {
    amount.current = data.recipientAmount
    token.current = data.paymentMethodId

    if (isSignedIn) {
      // Don't mess with any other state, just pay and go
      return await createAndPay()
    }

    email.setValue(data.payerEmail ?? '')
    name.setValue(data.payerName ?? '')
    phone.setValue(data.payerPhone ?? '')

    // Store email as a lead since they showed clear intent to pay, it'll get
    // ignored later on if they complete registration
    try {
      // Do _not_ await here, run it fire-and-forget in the background
      postData(
        '/api/leadgen/capture',
        {
          email: data.payerEmail,
          name: data.payerName ?? '',
          source: window.location.href,
          props: {
            "phone": data.payerPhone ?? '',
          }
      })
    } catch (error) {
      // Allow this to fail.
    }

    updatePage(Page.FinishRegistration)
    return true
  }

  if (isPaymentProcessing) {
    return <LoaderHeart intent="pay" />
  }

  if (page === Page.FinishRegistration) {
    const onUserInfoSubmit = async () => {
      if (!isSignedIn) {
        try {
          const regResult = await postData('/api/auth/register/password', { email, phone, name, password })
          const token = regResult.data.token
          signIn(token)
        } catch (error) {
          showError(readError(error, 'Could not register, please try again'))
          return
        }
      }

      // This has its own error handling
      await createAndPay()
    }

    return <BaseLayout showNavigation hasSections>
      <Section>
        <UserInfoForm {...{ email, name, password, phone }} intent="register" submit={onUserInfoSubmit} />
      </Section>
    </BaseLayout>
  }

  const disabled = !recipientName.isValid

  return (
    <BaseLayout>
      <Section id="CreateGroupForOther">
        <Form onSubmit={noop}>
          <Heading>Who is this group for?</Heading>

          {/* FIXME import/move styles from CGS */}
          <div id="TabBar">
            <Link to={builder.group.createForSelf} button tab>For me</Link>
            <Link to="#" button active tab>For Someone Else</Link>
          </div>

          <TextField
            binding={recipientName}
            placeholder="Enter Their Full Name"
            required
          />
          <h4 className="mg-top--sm">You’ll invite this person once you’re done creating your group.</h4>
          <P>{constantStrings.usOnlyNote}</P>

          <PaymentForm
            buttonText="Next"
            collectPayerEmail={!isSignedIn}
            collectPayerName={false}
            collectPayerPhone={!isSignedIn}
            currency={DEFAULT_CURRENCY}
            disabled={disabled}
            maxAmount={DONATION_LIMIT_MAX}
            minAmount={DONATION_LIMIT_MIN}
            onSubmit={onPaymentSubmit}
            title="title"
          />
        </Form>
      </Section>
    </BaseLayout>
  )
}
export default CreateGroupForOther
