import type { PaymentRequestItem } from '@stripe/stripe-js'

type PartialOptions = {
  total: PaymentRequestItem
  displayItems: PaymentRequestItem[]
}

const breakdownToPaymentRequestOptions = (breakdown: Api.Breakdown): PartialOptions => ({
  total: {
    label: 'Monthly payment amount',
    amount: parseInt(breakdown.chargedToPaymentMethod.amount, 10),
  },
  displayItems: [
    {
      label: 'Amount',
      amount: parseInt(breakdown.recipientAmount.amount, 10),
    },
    {
      label: 'Processing',
      amount: parseInt(breakdown.processingCost.amount, 10),
    },
    {
      label: 'Group Subscription',
      amount: parseInt(breakdown.platformFee.amount, 10),
    },
  ],
})

export default breakdownToPaymentRequestOptions
