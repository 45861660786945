import cn from 'classnames'

import {} from 'components'
import { Money as MoneyHelper } from 'data'

import './index.scss'

interface Props {
  // future scope: pass amounts to override defaults
  binding: FormBinding<Money>
}
const PayPad: React.FC<Props> = ({ binding }) => {
  const amounts = [
    25_00,
    50_00,
    100_00,
    150_00,
    200_00,
    300_00
  ].map(cents => MoneyHelper.amountWithCurrency(cents, 'USD'))
  return (
    <ul className="PayPad">
      {amounts.map(amount => <li key={amount.amount}>
        <button
          onClick={() => binding.setValue(amount)}
          className={cn({ active: MoneyHelper.isEqual(amount, binding.value)})}
        >{MoneyHelper.toFormatted(amount, true)}</button>
      </li>)}
    </ul>
  )
}

export default PayPad
