import React from 'react'

import BaseLayout from 'blocks/layout/base-layout'

import {
  Heading,
  KeyValueList,
  List,
  Loader,
  Money,
  Subhead,
  Time,
} from 'components'
import { Money as MoneyHelper } from 'data'
import { reduce, sort } from 'helpers'
import { useApiQuery } from 'hooks'
import { groupIdInUrl, mustBeSignedIn } from 'routes'

interface Props {
  groupId: GroupId
}
const DepositHistory: React.FC<Props> = ({ groupId }) => {
  const deposits = useApiQuery('/api/deposit/group', { groupId })
  const group = useApiQuery('/api/group/entity', { groupId })

  if (!deposits|| !group) {
    return <Loader />
  }

  const allTimeTotal = deposits.reduce(reduce.hasAmount.sum, MoneyHelper.zeroWithCurrency(group.defaultCurrency))

  return (<BaseLayout showNavigation showNavigationBackButton>
    <div id="DepositHistory">
      <Heading>Deposit History</Heading>
      <KeyValueList data={{
        Group: group.name ? group.name : group.recipientFullName,
        //Joined: 'joined',
        Deposits: deposits.length,
        'Lifetime Total': <Money amount={allTimeTotal} highlighted />,
      }} />
      <Subhead>My Deposits</Subhead>
        <List ordered>
        {deposits.sort(sort.createdNewestFirst).map(deposit=> (
          <List.Item key={deposit.id}>
            <Money amount={deposit.amount} highlighted />
            <> &ndash; </>
            <Time unixtimeSeconds={deposit.createdAt} />
          </List.Item>
        ))}
        </List>
    </div>
  </BaseLayout>)
}

export default mustBeSignedIn(groupIdInUrl(DepositHistory))
