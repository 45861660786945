import React, { useEffect } from 'react'
import classNames from 'classnames'

import { useAppWideState } from 'hooks'

const Messages: React.FC = () => {
  const app = useAppWideState()

  useEffect(() => {
    const dismissWithTimeout = (id: ToastId, timeoutMs: number = 5000) => {
      setTimeout(() => app.dismissMessage(id), timeoutMs)
    }

    if (app.messages.length > 0) {
      const lastMessage = app.messages[app.messages.length - 1]
      dismissWithTimeout(lastMessage.id)
    }
  }, [app]);

  return <div id="Messages">
    {app.messages.map(message => (
      <div key={message.id} className={classNames("Message", message.type)}>
        {message.message}

        {/* <button onClick={() => app.dismissMessage(message.id)} type="button">X</button> */}
      </div>
    ))}
  </div>
}

export default Messages
