import React from 'react'

import {
  Button,
  Form,
  FormRow,
  Heading,
  NameField,
  P,
  PostalCodeField,
  TextField,
} from 'components'
import { constantStrings } from 'data'
import {} from 'hooks'

interface Props {
  dobMonth: FormBinding<string>
  dobDay: FormBinding<string>
  dobYear: FormBinding<string>
  legalName: FormBinding<string>
  taxId: FormBinding<string>
  line1: FormBinding<string>
  line2: FormBinding<string>
  city: FormBinding<string>
  state: FormBinding<string>
  postalCode: FormBinding<string>
  nextPage: Thunk<void>
}
const EnterPII: React.FC<Props> = ({
  city,
  dobDay,
  dobMonth,
  dobYear,
  taxId,
  legalName,
  line1,
  line2,
  nextPage,
  postalCode,
  state,
}) => {
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    nextPage()
  }
  const disabled = !legalName.isValid
    || !dobMonth.isValid
    || !dobMonth.isValid
    || !dobYear.isValid
    || !taxId.isValid
    || !line1.isValid
    || !line2.isValid
    || !city.isValid
    || !state.isValid
    || !postalCode.isValid

  return <Form onSubmit={onSubmit}>
    <Heading>The Basics</Heading>

    <P>Please provide your personal info to collect your money.</P>

    <NameField
      binding={legalName}
      intent="kyc"
      required
    />

    <P classes="font-16px">* Name must match how it appears on your U.S. Government-issued I.D.</P>

    {/* For some reason, birthday only autocompletes the first field without the name attribute. Add it even though it does nothing. */}
    <FormRow label="Date of Birth">
      <TextField
        autoComplete="bday-month"
        binding={dobMonth}
        inline
        inputMode="numeric"
        max={12}
        maxLength={2}
        min={1}
        name="bday-month"
        pattern="[0-9]{1,2}"
        placeholder="MM"
        required
        step="any"
        type="number"
      />
      <TextField
        autoComplete="bday-day"
        binding={dobDay}
        inline
        inputMode="numeric"
        max={31}
        min={1}
        name="bday-day"
        pattern="[0-9]{1,2}"
        placeholder="DD"
        required
        type="number"
      />
      <TextField
        autoComplete="bday-year"
        binding={dobYear}
        inline
        inputMode="numeric"
        max={new Date().getFullYear() - 17} // mid-year bday
        min={1900}
        name="bday-year"
        pattern="[0-9]{4}"
        placeholder="YYYY"
        required
        type="number"
      />

    </FormRow>

    <FormRow label={<>Last 4 of <abbr title="Social Security Number">SSN</abbr> or <abbr title="Individual Taxpayer Identification Number">ITIN</abbr></>}>
      <TextField
        before={<span className="KycSsnPrefix">XXX &ndash; XX &ndash;</span>}
        binding={taxId}
        inputMode="numeric"
        maxLength={4}
        pattern="[0-9]{4}"
        placeholder=""
        required
      />
    </FormRow>

    <TextField
      autoComplete="address-line1"
      binding={line1}
      placeholder="Home Address"
      required
    />
    <TextField
      autoComplete="address-line2"
      binding={line2}
      placeholder="Address Line 2 (Optional)"
    />
    <TextField
      autoComplete="address-level2"
      binding={city}
      placeholder="City"
      required
    />
    <FormRow>
      <TextField
        autoComplete="address-level1"
        binding={state}
        inline
        maxLength={2}
        pattern="[A-Za-z]{2}"
        placeholder="State"
        required
      />
      <PostalCodeField
        binding={postalCode}
        inline
        intent="kyc"
        required
      />
    </FormRow>

    <P>{constantStrings.usOnlyNote}</P>

    <Button type="submit" text="Next" disabled={disabled} />
  </Form>
}

export default EnterPII
