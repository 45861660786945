import React from 'react'

import {} from 'components'
import {} from 'hooks'

interface Props {
  name: string
  role: 'recipient' | 'organizer' | 'member'
}
/**
 * Future changes will adjust styling based on role
 */
const Name: React.FC<Props> = ({ name , role}) => {
  if(role === 'recipient') {
    return <strong>{name} (Recipient)</strong>
  } else if (role === 'organizer') {
    return <strong>{name} (Organizer)</strong>
  } else {
    return <strong>{name}</strong>
  }
}

export default Name
