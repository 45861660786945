import React from 'react'

import { P } from 'components'

import RotatingText from './RotatingText'

import animation from './animation.svg'
// TODO: migrate styles
// import './index.css'

interface Props {
  intent: 'pay' | 'new-account' | 'kyc' | 'confirm-email' | 'other'
}
/**
 * WARNING: this is still tied to legacy styles!
 */
const LoaderHeart: React.FC<Props> = ({ intent }) => {
  let textArray: string[]

  switch (intent) {
    case 'pay':
      textArray = ['Just a moment...', 'Processing your payment...', 'Almost there...'];
    break;
    case 'confirm-email':
      textArray = ['Just a moment...', 'Confirming your email...', 'Almost there...'];
    break;
    case 'new-account': // fall through
      case 'kyc':
      textArray = ['Just a moment...', 'Almost there...', 'Nearly Done...'];
    break;
    case 'other':
      textArray = ['Just a moment...', 'Almost there...', 'Nearly Done...'];
    break;    
  }

  // return (
  //   <div id="LoaderHeart">
  //     <img src={animation} alt="" />
  //     <p><RotatingText textList={textArray} intervalMs={2000} /></p>
  //   </div>
  // )

  return (
    <div className="loader-heart">
      <div className="loader-heart__container">
        <div className="loader-heart__container-inner">
          <div className="loader-heart__image">
            <img src={animation} alt="" />
          </div>
          <div className="loader-heart__text">
            <P><RotatingText textList={textArray} intervalMs={2000} /></P>
          </div>
        </div>
      </div>
    </div>
  )
}
export default LoaderHeart
