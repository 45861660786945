import React from 'react';

import './index.css';

interface Props {
  children: Children
  // level: 1 | 2 | 3 | 4 | 5 | 6
}
const Heading: React.FC<Props> = ({ children }) => {
  return <h2 className="Heading">{children}</h2>;
};

export default Heading
