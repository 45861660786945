// Experimental for auth rework.
// https://dev.to/alexmercedcoder/simple-setup-for-application-wide-state-in-react-5e7g

import { useState, createContext, useContext, useEffect } from 'react'
import { useQueryClient } from 'react-query'

import { cookies } from 'helpers'
import { postData } from 'helpers/post-data'
import { builder } from 'routes'

const LOCAL_STORAGE_TOKEN_KEY = 'ag.auth_token'

type UserHasNotBeenChecked = undefined
type UserCheckedNotFound = null
// Note: this intentionally differs from Api.User.Entity for user's own profile
type User = { id: UserId, name: string, email: string } | UserCheckedNotFound | UserHasNotBeenChecked

// ToastId in global types
type Toast<T extends string> = {
  id: ToastId
  createdAt: Date
  message: string
  type: T,
}
type SuccessMessage = Toast<'success'>
type ErrorMessage = Toast<'error'>
type AnyMessage = SuccessMessage | ErrorMessage

type AppStateShape = {
  authToken: string | null
  messages: AnyMessage[]
  user: User
}
const initialState: AppStateShape = {
  authToken: localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY), // defaults to null if not set, which is fine
  messages: [],
  user: undefined, // undefined=not checked, null=nobody, obj=user
}

// As the name suggests, this will expose some data so the low level network
// wrappers (which are not in components) can get at the auth token.
let appStateForNetworkCallsOnlyUglyHack = initialState


type ContextShape = {
  showError: (message: string) => void
  showMessage: (message: string) => void
  dismissMessage: (id: ToastId) => void
  isSignedIn: boolean
  messages: AnyMessage[]
  signOut: () => void
  signIn: (arg0: string) => void
  user: User
}
const AppWideContext = createContext<ContextShape>({
  showError: (message) => {},
  showMessage: (message) => {},
  dismissMessage: (id) => {},
  isSignedIn: false,
  messages: [],
  signOut: () => {},
  signIn: (token) => {},
  user: undefined,
})

// Note: this intentionally runs (or tries to!) outside of the normal
// application flow/scope. The intent is to avoid leaking the fragment to
// third-party code.
export const consumeImpersonationToken = async (fragment: string) => {
  try {
    const result = await postData('/api/admin/ipt-exchange', { fragment })
    const authToken = result.data.token
    // FIXME: de-dupe from below logic..somehow?
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, authToken)
    appStateForNetworkCallsOnlyUglyHack.authToken = authToken
    // If done inline, there seems to be some sort of race condition where
    // some other part of the code (probably the router, but I can't see
    // where/how) grabs things too early and redirects to the sign in page.
    // Adding a small delay seems to avoid it. Yes, I'm sad about this fix.
    window.setTimeout(() => {
      // Replace gets the token out of the browser history too.
      window.location.replace(builder.group.all)
    }, 100)
  } catch (error) {
    // Silently fail and send back to the home page
    window.location.href = '/'
  }
}

// This should probably be refactored to use a reducer
const AppWideState: React.FC<ChildrenOnly> = ({ children }) => {
  const [appState, setAppState] = useState(initialState)
  const queryClient = useQueryClient()

  // Build the various elements exposed through Context
  const updateAuthToken = (token: string|null) => {
    if (token === null) {
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
    } else {
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token)
    }
    appStateForNetworkCallsOnlyUglyHack.authToken = token
    setAppState((prevState) => ({ ...prevState, authToken: token }))
  }

  const clearAuthToken = () => {
    updateAuthToken(null)
    // Blow all cached data away on sign out
    queryClient.clear()
  }

  const hasAuthToken = appState.authToken !== null

  useEffect(() => {
    const getUser = async () => {
      try {
        // Clumsy! This should run through the normal API hooks
        const userResult = await postData('/api/user/me')
        setAppState((prevState) => ({ ...prevState, user: userResult.data }))
      } catch (error) {
        clearAuthToken()
        setAppState((prevState) => ({ ...prevState, authToken: null, user: null }))
      }
    }
    if (hasAuthToken) {
      getUser()
    } else {
      // Don't try, go directly into unauth mode.
      setAppState((prevState) => ({ ...prevState, user: null }))
    }
  }, [hasAuthToken])

  const showError = (message: string) => {
    const newMessage: ErrorMessage = {
      id: generateId(),
      createdAt: new Date(),
      message,
      type: 'error',
    }
    setAppState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, newMessage],
    }))
  }

  const showMessage = (message: string) => {
    const newMessage: SuccessMessage = {
      id: generateId(),
      createdAt: new Date(),
      message,
      type: 'success',
    }
    setAppState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, newMessage],
    }))
  }

  const dismissMessage = (id: ToastId) => {
    setAppState((prevState) => ({
      ...prevState,
      messages: prevState.messages.filter(m => m.id !== id),
    }))
  }

  // Expose a limited API for interacting with authn data
  //
  // Note: isSignedIn intentionally will return an optimistic false-positive
  // when user info is loading or unknown. This helps avoid a race condition
  // in the ProtectedRoute logic where you look not signed in (but should be
  // treated optimistically ok) while the async getUser call is running but
  // incomplete.
  return (
    <AppWideContext.Provider
      value={{
        showError,
        showMessage,
        dismissMessage,
        messages: appState.messages,
        signIn: updateAuthToken,
        signOut: clearAuthToken,
        isSignedIn: hasAuthToken,
        user: appState.user,
      }}
    >
      {children}
    </AppWideContext.Provider>
  )
}

const generateId = (): ToastId => {
  const timestamp = new Date().getTime()
  const random = Math.floor(Math.random() * 100000)
  // Combine the timestamp and random number to create a unique ID
  return `${timestamp}-${random}` as ToastId
}

export { AppWideState, appStateForNetworkCallsOnlyUglyHack }
export const useAppWideState = () => useContext(AppWideContext)
