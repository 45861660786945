import React, { useEffect } from 'react'

import {
  Heading,
  Link,
  P,
} from 'components'
import { showConfetti } from 'helpers'
import { builder } from 'routes'

interface Props {
  groupId: GroupId
}
const ThankYou: React.FC<Props> = ({ groupId }) => {
  useEffect(showConfetti, [])
  return (
    <>
      <Heading>Thank You!</Heading>
      <P>Your bank account info has been added.</P>
      <P>Please allow up to <strong>7</strong> business days for your first deposit to appear in your bank account.</P>
      <Link to={builder.group.view(groupId)} button>Continue</Link>
    </>
  )
}

export default ThankYou
