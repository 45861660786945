import React from 'react'

import { ContactLink, Heading, Link, List, Loader, P } from 'components'
import { useApiQuery } from 'hooks'
import { builder, groupIdInUrl, mustBeSignedIn } from 'routes'

import BaseLayout from 'blocks/layout/base-layout';

interface Props {
  groupId: GroupId
}
const GroupSettings: React.FC<Props> = ({ groupId }) => {
  const roleData = useApiQuery('/api/group/me', { groupId })
  if (!roleData) {
    return <Loader />
  }

  // Group-specific settings:
  // - Change/Cancel payment (payers)
  // - Restart payment (organizer that has previously canceled)
  // - View/Edit Bank Info (recipient)
  // - Add/update KYC (recipient)

  return (
    <BaseLayout showNavigationBackButton showNavigation>
      <Heading>Group Settings</Heading>
      <P>Manage your account information below.</P>
      <List>
        {roleData.permissions.viewDeposits && (
          <List.Item>
            <Link to={builder.group.depositHistory(groupId)}>
              Deposit History
            </Link>
          </List.Item>
        )}
        {roleData.permissions.viewPayments && (
          <List.Item>
            <Link to={builder.group.paymentHistory(groupId)}>
              Payment History
            </Link>
          </List.Item>
        )}
        {/* This will come back once we HAVE user settings */}
        {/* <List.Item><Link to={builder.user.settings}>Personal Settings</Link></List.Item> */}
      </List>

      <P centered>
        <strong>Need help?</strong>
        <br />
        <ContactLink>Contact Us</ContactLink>
      </P>
    </BaseLayout>
  )
}

export default mustBeSignedIn(groupIdInUrl(GroupSettings))
