import { Money } from 'data'

const greatestFirst = (a: Money, b: Money): number => {
  Money.assertSameCurrency(a, b)
  return parseInt(b.amount, 10) - parseInt(a.amount, 10)
}

/**
 * Callbacks to be provided directly to Array.sort; e.g.
 * `myList.sort(sort.createdNewestFirst)`
 */
const sort = {

  /**
   * For objects with an `amount` property. If a value is null ("I'm not
   * telling you") it'll be sorted to the end.
   */
  amountHighestFirst: <T extends { amount: Money|null }>(lhs: T, rhs: T): number => {
    if (lhs.amount === null) {
      return rhs.amount === null ? EQUAL : RHS_FIRST
    }
    if (rhs.amount === null) {
      return LHS_FIRST
    }

    return greatestFirst(lhs.amount, rhs.amount)
  },

  /**
   * For objects with a createdAt property
   */
  createdNewestFirst: <T extends { createdAt: UnixtimeSeconds }>(lhs: T, rhs: T): number => rhs.createdAt - lhs.createdAt,
  money: {
    greatestFirst,
  },

}

const LHS_FIRST = -1
const EQUAL = 0
const RHS_FIRST = 1

export default sort
