import React, { useEffect, useState } from 'react'

import { useStripe } from '@stripe/react-stripe-js'
import type { PaymentRequest } from '@stripe/stripe-js'

import { AnchorButton, Icon, SignInLink, P, Section, PaymentBreakdown } from 'components'
import { useAppWideState } from 'hooks'

import CreditCardForm from './CreditCardForm'
import PaymentRequestButton from './PaymentRequestButton'
import breakdownToPaymentRequestOptions from './breakdownToPaymentRequestOptions'

// https://stripe.com/docs/stripe-js/elements/payment-request-button
enum Mode {
  Form, // Type it yourself
  PaymentRequestButton, // ApplePay/GooglePay
}

const useCC = 'Pay by Card instead?'
const useAP = 'Use Apple Pay instead?'
const useGP = 'Use Google Pay instead?'

export type PayerAndPaymentMethodData = Omit<PaymentFormResult, 'recipientAmount'>

/**
 * This is a wrapper component that allows toggling between the CC form and
 * ApplePay/GooglePay. If AP/GP are unsupported then only the CC form will be
 * displayed without the option to toggle.
 *
 * CC Form will show the input fields plus the submit button
 *
 * AP/GP will show only the button, which starts the OS/browser-native
 * interaction when pressed.
 *
 * onPaymentSubmit - async ({name/email/phone/pmId}) => boolean
 */
interface Props {
  breakdown: Api.Breakdown
  buttonText: string
  collectPayerEmail: boolean
  collectPayerName: boolean
  collectPayerPhone: boolean
  disabled: boolean
  onPaymentSubmit: (data: PayerAndPaymentMethodData) => Promise<boolean>
  v2?: boolean
  onComponentLoad?: () => void
}
const ModalPaymentForm: React.FC<Props> = ({
  breakdown,
  buttonText,
  collectPayerEmail,
  collectPayerName,
  collectPayerPhone,
  disabled,
  onPaymentSubmit,
  onComponentLoad,
  v2 = true // FIXME: setting to true for testing purposes only
  // v2 = (window as any).flagsmith?.flags?.join_group_redesign?.enabled || false
}) => {
  const stripe = useStripe()
  const { isSignedIn } = useAppWideState()

  const [canUseApplePay, setCanUseApplePay] = useState(false)
  const [canUseGooglePay, setCanUseGooglePay] = useState(false)

  // Holds the actual Stripe PM if/when initialized
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest|null>(null)

  // When Stripe loads, check for the availability of Payment Request
  // (applepay/googlepay/etc) and prepare it for use
  useEffect(() => {
    if (stripe === null) {
      return
    }

    const currency = breakdown.chargedToPaymentMethod.currency

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: currency.toLowerCase(),
      ...breakdownToPaymentRequestOptions(breakdown),
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true, // not technically needed if !collectPayerPhone but leaving on regardless
    })

    const checkAvailableMethods = async () => {
      const res = await pr.canMakePayment()
      if (res !== null) {
        setPaymentRequest(pr)
        setCanUseApplePay(res.applePay)
        setCanUseGooglePay(res.googlePay)
      }
    }
    checkAvailableMethods()
  }, [stripe])

  const canSwitchModes = canUseApplePay || canUseGooglePay
  const [mode, setMode] = useState(Mode.Form)
  // Switch to AP/GP as default if they become activated.
  useEffect(() => {
    if (canSwitchModes) {
      setMode(Mode.PaymentRequestButton)
      if(!!onComponentLoad) onComponentLoad()
    }
  }, [canSwitchModes, onComponentLoad])

  let switchModeText: string
  const isCC = document.querySelector('.donate-form')

  if (mode === Mode.PaymentRequestButton) {
    switchModeText = useCC
    if (isCC !== null) isCC.classList.remove('creditCard')
  } else if (canUseApplePay) {
    switchModeText = useAP
    if (isCC !== null) isCC.classList.add('creditCard')
  } else if (canUseGooglePay) {
    switchModeText = useGP
    if (isCC !== null) isCC.classList.add('creditCard')
  } else {
    switchModeText = '(checking for ApplePay support)'
  }

  const switchMode = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (mode === Mode.PaymentRequestButton) {
      setMode(Mode.Form)
    } else {
      setMode(Mode.PaymentRequestButton)
    }
  }

  let form

  form = <CreditCardForm
    buttonText={buttonText}
    collectPayerEmail={collectPayerEmail}
    collectPayerName={collectPayerName}
    collectPayerPhone={collectPayerPhone}
    disabled={disabled}
    onPaymentSubmit={onPaymentSubmit}
    appleGooglePay={canSwitchModes}
    switchModeText={switchModeText}
    switchModeFn={switchMode}
  />

  if (mode === Mode.PaymentRequestButton) {
    if (!paymentRequest) {
      throw new Error('PR is missing even though AP/GP are enabled')
    }
    // collectPayerName intentionally omitted from AP/GP flow - payer phone
    // is only an onboarding flow workaround anyway.
    form = <PaymentRequestButton
      breakdown={breakdown}
      collectPayerEmail={collectPayerEmail}
      collectPayerPhone={collectPayerPhone}
      disabled={disabled}
      onPaymentSubmit={onPaymentSubmit}
      paymentRequest={paymentRequest}
    />
  }

  // NOTE from MW on 3/8/24, this may not needed if we pull in the CredtCard2 Component
  //
  // if (v2) {
  //   return (
  //     <>
  //       {form}
  //       <p><Icon icon="lock" size="small" /> Your name and gift amount are <strong>only visible</strong> to others allowed into this group.</p>
  //       <hr />
  //       <div id="ModalPaymentFormFooter">
  //         {isSignedIn || <SignInLink id="ModalPaymentFormFooterSignIn" />}
  //         {canSwitchModes && <AnchorButton onClick={switchMode} id="ModalPaymentFormFooterSwitchMode">
  //           {switchModeText}
  //         </AnchorButton>}
  //       </div>
  //     </>
  //   )
  // }

  return (
    <>
      {form}
      {mode !== Mode.PaymentRequestButton && <PaymentBreakdown breakdown={breakdown} />}
      {mode === Mode.PaymentRequestButton && <P centered>
        <AnchorButton onClick={switchMode}>
          {switchModeText}
        </AnchorButton>
      </P>}
    </>
  )
}

export default ModalPaymentForm
