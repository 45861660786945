import React, { useEffect, useState } from 'react'

import { KeyValueList } from 'components'
import { GIT_COMMIT } from 'config'
import { useAppWideState, useFeatureToggle } from 'hooks'

const initialCopyButtonText = 'Click to copy info'

const Footer: React.FC = () => {
  const [dir, setDir] = useState<'ltr'|'rtl'>('ltr')
  const [copyButtonText, setCopyButtonText] = useState(initialCopyButtonText)

  useEffect(() => {
    document.querySelector('html')!.setAttribute('dir', dir)
  }, [dir])

  const toggleDir = () => setDir((prev) => prev === 'ltr' ? 'rtl' : 'ltr')

  const app = useAppWideState()
  const showDebugFooter = useFeatureToggle('showDebugFooter')
  if (!showDebugFooter) {
    return null
  }

  const footerData: DictOf<string|React.ReactNode> = {
    Host: window.location.host,
    Version: GIT_COMMIT,
    'Signed in': app.isSignedIn ? 'Yes' : 'No',
    Time: <CurrentTime />,
    URL: window.location.toString(),
    'User Agent': navigator.userAgent,
    // Caution: these will go stale without the timer!
    Viewport: `${window.innerWidth} x ${window.innerHeight}`,
  }
  if (app.isSignedIn) {
    footerData['User ID'] = app.user ? app.user.id : 'Not signed in'
  }

  const copyDebugInfo = (e: React.MouseEvent) => {
    e.preventDefault()
    let clipboardText = ''
    for (const key in footerData) {
      clipboardText += `${key}: ${footerData[key]}\n`
    }
    navigator.clipboard.writeText(clipboardText)

    setCopyButtonText('Copied!')
    setTimeout(() => setCopyButtonText(initialCopyButtonText), 2000)
  }

  return (
    <footer id="Footer">
      <KeyValueList data={{
        ...footerData,
        'RTL text': <input type="checkbox" checked={dir === 'rtl'} onChange={toggleDir} />,
        'Ticket': <button onClick={copyDebugInfo}>{copyButtonText}</button>,
      }} />
    </footer>
  )
}

const CurrentTime: React.FC = () => {
  const [time, setTime] = useState(new Date())
  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date())
    }, 1000)
    return () => clearInterval(timer)
  }, [])
  return <>{time.toUTCString()}</>
}

export default Footer
