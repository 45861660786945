import { cookies } from 'helpers'
import { createContext, useRef, useState, useEffect } from 'react'
import flagsmith from 'flagsmith'
import { NODE_ENV } from 'config'

/**
 * This loosely reimplements what FlagSmith provides in their own React
 * integration, complete with context. The operative difference is that there
 * seems to be an issue where the flags don't appear to get pushed out back
 * into the rest of the app beyond the first init() call. Since we call
 * identify() after init(), any flag changes that take place as a result don't
 * reliably propagate.
 *
 * Consequently, this does a very simple init+onchange, throwing the
 * any-time-flags-change value into React state which is exposed through this
 * Context. Our two feature-flag hooks read this Context and make it available
 * to the rest of the app with a better API.
 */

type Feature = {
  // id: number
  enabled: boolean
  value: boolean | string | null | number | undefined
}

interface ContextShape {
  loadedValues: DictOf<Feature>
}
export const FlagsmithContext = createContext<ContextShape>({
  loadedValues: {},
})

interface Props {
  environmentID: string
}
export const FlagsmithProvider: React.FC<Props & HasChildren> = ({ children, environmentID }) => {
  const init = useRef(false)

  const [loadedValues, setLoadedValues] = useState<DictOf<Feature>>({})
  useEffect(() => { if(NODE_ENV !== 'production') { console.log("flagsmith values:", loadedValues)} }, [loadedValues])

  // One time only, run the init code. Set it to push new values into the React
  // state above, which is what's then threaded through the Context.
  if (!init.current) {
    init.current = true
    flagsmith.init({
      environmentID,
      onChange: (prev, ls) => {
        // @ts-ignore
        setLoadedValues(flagsmith.getAllFlags())
      },
      onError: (e) => {
        console.error('FlagSmith error', e)
      },
      identity: cookies.tid ?? 'no-user-no-tid',
    })
  }

  return (
    <FlagsmithContext.Provider value={{ loadedValues }}>
      {children}
    </FlagsmithContext.Provider>
  )
}
