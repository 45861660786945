import React, { useState } from 'react'

import { readError } from 'helpers'
import { postData } from 'helpers/post-data'

import {
  AnchorButton,
  Loader,
  Name,
  P,
  Subhead,
  Link
} from 'components'
import { useAppWideState, useRedirect } from 'hooks';
import { NavigationTarget } from 'data';
import { builder } from 'routes';

interface Props {
  doNotCancel: Thunk<void>
  group: Api.Entity.Group
  groupId: GroupId
  isOrganizer: boolean
  subscriptionId: SubscriptionId
}
enum State {
  OnPage,
  Canceling,
  Canceled,
}

const Cancel: React.FC<Props> = ({ doNotCancel, group, isOrganizer, subscriptionId, groupId }) => {
  const [state, setState] = useState(State.OnPage)
  const redirect = useRedirect()
  const { showError } = useAppWideState()

  const cancelText = isOrganizer
    ? 'As the organizer, you will still have access to the group.'
    : 'You will no longer have access to the group.'


  const cancelPayment = async () => {
    try {
      setState(State.Canceling)
      await postData('/api/subscription/cancel', { subscriptionId })
      setState(State.Canceled)
      redirect(new NavigationTarget({ url: builder.site.home }))
    } catch (error) {
      setState(State.OnPage)
      console.log(error)
      showError(readError(error, "There was an error canceling your subscription. Please try again shortly; if the issue persists, please contact customer support."))
    }
  }

  // FIXME: clean this up, duped from parent component. It should reload the
  // data and update there. Sub API is not great right now.
  if (state === State.Canceled) {
    return <P><strong>This payment has been canceled.</strong></P>
  }


  return (
    <>
      {/* HACK: support and change to intent=warning? */}
      {/*<Link to={builder.group.changeSubscription(groupId)}>Change Payment</Link>*/}
      <Subhead><span style={{color: 'var(--warning)'}}>Cancel Payment</span></Subhead>
      {/* FIXME: this looks dumb */}
      <P>You will no longer be charged monthly payments to <Name name={group.recipientFullName} role="recipient" />. {cancelText} Are you sure?</P>
      {state === State.Canceling && <Loader />}
      {state === State.OnPage && <>
        <P centered>
          <AnchorButton
            intent="warning"
            onClick={cancelPayment}
          >Cancel Payment</AnchorButton>
        </P>
        <P centered>
          <AnchorButton onClick={doNotCancel}>Go Back</AnchorButton>
        </P>
      </>}
    </>
  )
}

export default Cancel
