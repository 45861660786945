import React from 'react'

import { Loader, Link } from 'components'
import { useApiQuery } from 'hooks'
import { builder } from 'routes'

interface Props {
  groupId: GroupId
}
const GroupListSimple: React.FC<Props> = ({ groupId }) => {
  const group = useApiQuery('/api/group/entity', { groupId })
  const roles = useApiQuery('/api/group/me', { groupId })

  if (!group || !roles) {
    return <div>&nbsp;</div>
  }

  let roleText = 'Member'
  if (roles.roles.isOrganizer) {
    roleText = 'Organizer'
  } else if (roles.roles.isRecipient) {
    roleText = 'Recipient'
  }

  const name = group.name ? group.name : group.recipientFullName

  return (
    <section>
      <Link to={builder.group.view(groupId)}>{name}</Link> ({roleText})
    </section>
  )
}

export default GroupListSimple