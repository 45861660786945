import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  Button,
  EmailField,
  Form,
  Heading,
  NameField,
  P,
  PhoneNumberField,
  TextArea,
} from 'components'

import { getFirstName, readError } from 'helpers'
import { useAppWideState, useFormField } from 'hooks'

import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'

import ThankYou from './ThankYou'

const Contact: React.FC = () => {
  const state: ContactFormState = useLocation().state
  const { isSignedIn, user, showError } = useAppWideState()
  const [sent, setSent] = useState(false)
  console.debug(state)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const name = useFormField('')
  const email = useFormField('')
  const phone = useFormField('')
  const content = useFormField(state?.defaultMessage ?? '')


  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      await postData('/api/contact', {
        email,
        phone,
        content,
        name,
      })

      setSent(true)
    } catch (error) {
      showError(readError(error, 'Error sending message, please try again'))
    } finally {
      setIsSubmitting(false)
    }
  }

  if (sent) {
    return <ThankYou destination={state?.afterContactUrl} />
  }

  let disabled = isSubmitting || !content.isValid
  if (!isSignedIn) {
    disabled = disabled || !name.isValid || !email.isValid || !phone.isValid
  }

  const headingText = isSignedIn && user
    ? `Hi ${getFirstName(user.name)}!`
    : 'Contact Us'

  return (
    <Form onSubmit={onSubmit}>
      <Heading>{headingText}</Heading>

      <P>Please contact us below...</P>

      {!isSignedIn && <NameField
        binding={name}
        intent="self"
        required
      />}

      {!isSignedIn && <EmailField
        binding={email}
        intent="contact"
        required
      />}

      <TextArea
        binding={content}
        placeholder="Type your message here..."
        required
      />

      {!isSignedIn && <PhoneNumberField
        binding={phone}
        intent="contact"
        required
      />}

      <Button type="submit" disabled={disabled} text={isSubmitting ? 'Sending...' : 'Send'} />
    </Form>
  )
}

const Wrapper: React.FC = () => (
  <BaseLayout showNavigation showNavigationBackButton>
    <Contact />
  </BaseLayout>
)

export default Wrapper
