import { Link, P, Section, } from 'components'
import { useApiQuery, useFeatureToggle } from 'hooks'
import { builder } from 'routes'

export const CreateAnotherGroup: React.FC = () => {
  const showGroupCreateLinks = useFeatureToggle('showCreateGroupLinks')
  const myPermissions = useApiQuery('/api/user/permissions', undefined)

  if (!showGroupCreateLinks || !myPermissions) {
    return null
  }

  if (!myPermissions.canOrganizeAdditionalGroups) {
    return null
  }
  return (
    <Section headerLeading={<h3>Want to help another person?</h3>}>
      <P>AutoGive is the simple, invite-only way to collect monthly payments for yourself or someone you care about.</P>
      <P>Create your new group now!</P>

      <Link button to={builder.group.create}>Create a New Group</Link>
    </Section>
  )
}

export const ContactUs: React.FC = () => {
  const showGroupCreateLinks = useFeatureToggle('showCreateGroupLinks')
  const myPermissions = useApiQuery('/api/user/permissions', undefined)

  if (!showGroupCreateLinks || !myPermissions) {
    return null
  }

  if (!myPermissions.canOrganizeAdditionalGroups) {
    return null
  }
  return (
    <Section headerLeading={<h3>About AutoGive</h3>}>
      <P>AutoGive is the simple, invite-only way to collect monthly payments for yourself or someone you care about.</P>
      <P><strong>Have a question about this group?</strong></P>

      <Link button to={builder.site.contact}>Contact Us</Link>
    </Section>
  )
}
