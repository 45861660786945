import React from 'react'

const PhoneInfo: React.FC<Api.PhoneInfo> = ({ isVerified, last4 }) => {
  return (
    <span className="PhoneInfo">
      Phone ***-{last4} (
      {isVerified ?
        <span className="Verified">Verified</span>
        : <span className="Unverified">Not Verified</span>
      })
    </span>
  )
}

export default PhoneInfo
