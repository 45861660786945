import React from 'react'

import { Highlighted } from 'components'
import { Money as MoneyHelper } from 'data'

/**
 * Displays a locale- and currency-aware formatted amount of money.
 *
 * `addStyleHooks`: if true, this will return a series of <span> tags with
 * additional CSS classes to target page-specific control. Use with caution,
 * as this explicitly violates our general standards of components being
 * responsible for their own CSS.
 *
 * The output format will contain three elements:
 * <>
 *   <span className="Money symbol" />
 *   <span className="Money amount" />
 *   <span className="Money fraction" />
 * </>
 *
 * Note that the order of these elements are not guaranteed, as some locales
 * will render the symbol at the end of the amount, rather than the en-US
 * typical start.
 *
 * This format is subject to change.
 *
 * `highlighted`: if true, wraps the output in a <Highlighted> component.
 * Cannot be combined with `addStyleHooks`.
 *
 * `locale`: override the default locale. Really only intended for debugging.
 */

interface Props {
  addStyleHooks?: boolean
  amount: Money,
  highlighted?: boolean
  locale?: string
}
const Money: React.FC<Props> = ({ addStyleHooks, amount, highlighted = false, locale }) => {

  if (addStyleHooks && highlighted) {
    throw new Error('addStyleHooks and highlighted are mutually exclusive.')
  }

  const fmt = new Intl.NumberFormat(locale, {
    currency: amount.currency,
    style: 'currency',
  })
  const exponent = MoneyHelper.exponents[amount.currency]
  const factor = Math.pow(10, exponent)
  const dollars = parseInt(amount.amount, 10) / factor

  if (!addStyleHooks) {
    const displayValue = fmt.format(dollars)
    return highlighted ? <Highlighted>{displayValue}</Highlighted> : <>{displayValue}</>
  }

  // This is NOT CORRECT - it condenses too many parts together and loses
  // components like `literal` in some cases. For now we only really need to
  // handle USD in en-US so it's not horrible, but not really right either.

  let sm = new SplitMoney()
  let lastPart: Intl.NumberFormatPart | undefined = undefined
  for (const part of fmt.formatToParts(dollars)) {
    switch (part.type) {
      case 'currency':
        sm.symbol = part.value
        if (lastPart === undefined) {
          sm.symbolPosition = 'start'
        } else {
          // probably wrong in some RTL locales
          // console.debug(sm, part, lastPart, locale, amount, fmt.format(dollars))
          sm.symbolPosition = 'end'
        }
      break
      case 'decimal':
      case 'fraction':
        sm.fraction += part.value
      break
      case 'integer':
      case 'group':
        sm.amount += part.value
      break
      case 'literal':
        if (lastPart?.type === 'currency') {
          sm.symbol += part.value
        } else if (lastPart?.type === 'fraction') {
          sm.fraction += part.value
        } else if (lastPart?.type === 'integer') {
          sm.amount += part.value
        } else {
          // Only case I've found so far:
          // lastPart is undefined and the value is U+200F (right to left mark)
          console.warn(
            'Unconsumed literal in Money display',
            part,
            lastPart,
            part.value.charCodeAt(0),
          )
        }
      break
      default:
        // I have not run into any here; pretty sure the only other declared
        // possibilities are for formats other than currency.
        console.warn(
          'Unconsumed part in Money display',
          part,
          part.value.charCodeAt(0),
      )
      break
    }
    lastPart = part
  }

  // This is pretty shaky with anything RTL
  return (
    <>
      {sm.symbolPosition !== 'end' && <span className="Money symbol">{sm.symbol}</span>}
      <span className="Money amount">{sm.amount}</span>
      <span className="Money fraction">{sm.amount.length < 4 ? sm.fraction : null}</span>
      {sm.symbolPosition === 'end' && <span className="Money symbol">{sm.symbol}</span>}
    </>
  )
}

class SplitMoney {
  symbol: string = '' // "$"
  amount: string = '' // "1,500"
  fraction: string = '' // ".00"
  symbolPosition: 'start' | 'end' | undefined
}

export default Money
