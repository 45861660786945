import React, { useEffect } from 'react'

import { Heading, Link, Name, P } from 'components'
import { showConfetti } from 'helpers'
import { builder } from 'routes'

interface Props {
  groupId: GroupId
  name: string
}
const InviteSent: React.FC<Props> = ({ name, groupId }) => {
  useEffect(showConfetti, [])

  return <>
    <Heading>Invite Sent!</Heading>
    <P>Thanks for inviting your Recipient to your group.</P>
    <P>You'll be notified once <Name name={name} role="recipient" /> successfully provides their banking info and joins your group.</P>

    <Link to={builder.group.view(groupId)} button>Back to Group</Link>
  </>
}

export default InviteSent
