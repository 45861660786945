import React, { Children, useState } from 'react'
import classNames from 'classnames'

import './index.css'

import Tab, { ITabProps } from './Tab'

// Possible future enhancement: renderInactivePanels=false
// This could allow keeping the hidden tabs mounted in the DOM, which would
// allow state to persist across visibility changes.
interface Props {
  children: React.ReactElement<ITabProps>[]
}
interface SubComponent {
  Tab: typeof Tab
}
const Tabs: React.FC<Props> & SubComponent = ({ children }) => {
  // Read out the actual jsx properties of the provided <Tab> components. Their
  // ids are used for the selected tab state management; the titles and panels
  // are used to render the button contents and the actual tab when selected.
  const tabsProps = Children.map(children, tab => tab.props)
  const ids = tabsProps.map(tabProps => tabProps.id)
  const tabData = tabsProps.reduce((acc, tabProps) => ({
    ...acc,
    [tabProps.id]: { title: tabProps.title, panel: tabProps.panel }
  }), {} as {[key: string]: { title: string, panel: React.ReactNode}})

  const [selectedTabId, setSelectedTabId] = useState(ids[0])

  return (
    <div className="Tabs">
      <div className="TabBar">
        {ids.map(id => (
          <button
            className={classNames("Tab", { selected: selectedTabId === id })}
            key={id}
            onClick={() => setSelectedTabId(id)}
          >
            {tabData[id].title}
          </button>
        ))}
      </div>
      <div className="SelectedTabContents">
        {tabData[selectedTabId].panel}
      </div>
    </div>
  )
}

Tabs.Tab = Tab

export default Tabs
