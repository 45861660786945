import React, { useEffect } from 'react'

import { useAppWideState, useApiQuery, useFormField, usePageFlow } from 'hooks'
import { groupIdInUrl, mustBeSignedIn } from 'routes'

import InviteForm from './InviteForm'
import InviteSent from './InviteSent'
import Confirm from './Confirm'

import BaseLayout from 'blocks/layout/base-layout'
import { readError } from 'helpers'
import { postData } from 'helpers/post-data'

enum Page {
  DisplayForm = '',
  ShowConfirmation = 'confirm',
  InviteSent = 'sent',
}
interface Props {
  groupId: GroupId
}
const InviteRecipient: React.FC<Props> = ({ groupId }) => {

  const { showError } = useAppWideState()
  const [page, updatePage] = usePageFlow<Page>(Page.DisplayForm)
  const group = useApiQuery('/api/group/entity', { groupId })

  const name = useFormField('')
  const email = useFormField('')
  const phone = useFormField('')
  const message = useFormField('')

  const nameSetValue = name.setValue
  useEffect(() => {
    if (page === Page.DisplayForm) {
        postData('/api/analytics/track', { funnel: "RECIPIENT_INVITE", event: "APP_RI_INVITE_RECIPIENT_INFO", url: window.location.href, groupId: groupId })
    } else if (page === Page.ShowConfirmation) {
        postData('/api/analytics/track', { funnel: "RECIPIENT_INVITE", event: "APP_RI_CONFIRM_RECIPIENT_INFO", url: window.location.href, groupId: groupId })
    }
  }, [page, groupId])
  useEffect(() => {
    if (group) {
      nameSetValue(group.recipientFullName)
    }
  }, [group, nameSetValue])

  const submit = async () => {
    try {
      await postData(`/api/group/inviteRecipient`, {
        name,
        email,
        groupId,
        phone,
        message,
      })
      updatePage(Page.InviteSent)
    } catch (error) {
      console.error(error)
      showError(readError(error, 'There was an issue inviting the recipient. Please try again.'))
      // Send back to edit page on error
      updatePage(Page.DisplayForm)
    }
  }

  if (page === Page.DisplayForm) {
    return <InviteForm
      email={email}
      groupId={groupId}
      message={message}
      name={name}
      nextPage={(e) => { e.preventDefault(); updatePage(Page.ShowConfirmation) }}
      phone={phone}
    />
  } else if (page === Page.ShowConfirmation) {
    return <Confirm
      email={email}
      goBack={() => updatePage(Page.DisplayForm)}
      message={message}
      name={name}
      phone={phone}
      submit={submit}
    />
  } else if (page === Page.InviteSent) {
    return <InviteSent groupId={groupId} name={name.value} />
  } else {
    throw new Error('Invalid page state')
  }
}
// Extract route info to component property
const Wrapper: React.FC<Props> = ({ groupId }) => {
  return (
    <BaseLayout showNavigation showNavigationBackButton>
      <InviteRecipient groupId={groupId} />
    </BaseLayout>
  )
}

export default mustBeSignedIn(groupIdInUrl(Wrapper))
