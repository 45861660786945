import React, { useEffect, useState } from 'react'

import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'

import {
  Button,
  Form,
  Heading,
  Link,
  Loader,
  P,
  TextArea,
  TextField
} from 'components'
import { NavigationTarget } from 'data'
import { readError } from 'helpers'
import { useEntity, useFormField, useRedirect, useAppWideState } from 'hooks'
import { builder, groupIdInUrl, mustBeSignedIn } from 'routes'

interface Props {
  groupId?: GroupId
}
const SetPublicMessage: React.FC<Props> = () => {
  const { isSignedIn, showError } = useAppWideState()
  const name = useFormField('')
  const message = useFormField('')  
  const [isSubmitting, setIsSubmitting] = useState(false)
  const redirect = useRedirect()
  // const disabled = !message.isValid || !name.isValid
  const groupFor = sessionStorage.getItem('groupFor')

  useEffect(() => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: "APP_GC_GROUP_NAME", url: window.location.href })
  }, [])

  useEffect(() => {
    const storedName = sessionStorage.getItem('groupName')
    const storedDesc = sessionStorage.getItem('groupDesc')
    if (storedName) {
      name.setValue(storedName)
    }
    if (storedDesc) {
      message.setValue(storedDesc)
    }    
  }, [])

  const onType = (e: React.KeyboardEvent) => {
    setIsSubmitting(false)
  }

  const nameMessage = !name.isValid && isSubmitting ? 'Group Name is required' : null
  const descMessage = !message.isValid && isSubmitting ? 'Group Description is required' : null
  const isLoading = nameMessage === null && descMessage === null && isSubmitting ? true : false

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)

    if (isSignedIn && groupFor === 'self' && (name.isValid && message.isValid)) {
      try { 
        const group = await postData('/api/group/create/self')
        const groupId = group.data.id
        await postData('/api/group/editPublicMessage', { groupId, message })
        await postData('/api/group/update', { groupId, name })    

        redirect(new NavigationTarget({
          url: builder.group.inviteContacts(groupId),
        }))              
        return true
      } catch (error) {
        showError(readError(error, 'Could not create group'))
        return false
      }             
    } else if (name.isValid && message.isValid) {
      sessionStorage.setItem('groupName', name.value)
      sessionStorage.setItem('groupDesc', message.value)

      const url = builder.group.setSelfGroup
      if (name.isValid && message.isValid) redirect(new NavigationTarget({ url }))
    }
  }  

  return (<BaseLayout showNavigationBackButton>
    <div id="SetWelcomeNote">
      <Form onSubmit={onSubmit}>
        <Heading>Create Your Group</Heading>
        <h5>Invited donors will see the information you enter below.</h5>      

        <TextField
          binding={name}
          placeholder="Title (e.g. Help with College)"
          required={true}
          errorMessage={nameMessage}
       
        />
           {/*onKeyPress={onType}*/}

{/*      Old header copy
        <Heading>Message to Donors</Heading>*/}      
        <TextArea
          binding={message}
          placeholder="Write a short message to describe your group and why you're collecting donations..."
          required={true}
          errorMessage={descMessage}
          onKeyPress={onType}
        />
        
        <Button type="submit" text="Next" loading={isLoading} />
        <P centered={true} classes="pd-lfrt">Don’t worry, you can change this information later.</P>        
        {/*disabled={disabled}*/}
      </Form>
    </div>
  </BaseLayout>)
}

export default SetPublicMessage
