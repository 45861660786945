import { ProgressBar } from 'components'
import { useApiQuery } from 'hooks'

interface Props {
  groupId: GroupId
}
const GroupProgressBar: React.FC<Props> = ({ groupId }) => {
  const group = useApiQuery('/api/group/entity', { groupId })
  const groupStats = useApiQuery('/api/group/stats', { groupId })
    // Not loaded
  if (!group || !groupStats) {
    return null
  }

  // Not visible
  if (!group.goalAmount || !groupStats.sum) {
    return null
  }

  return <ProgressBar
    goal={group.goalAmount}
    donated={groupStats.sum}
    name={group.recipientFullName}
  />
}

export default GroupProgressBar
