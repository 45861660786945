import { AnalyticsBrowser } from '@segment/analytics-next'

import { REACT_APP_SEGMENT_API_KEY } from 'config'

let _analytics: AnalyticsBrowser|null = null
let loaded = false
const useSegmentAnalytics = (): AnalyticsBrowser|null => {
  if (!loaded) {
    if (REACT_APP_SEGMENT_API_KEY) {
      _analytics = AnalyticsBrowser.load({ writeKey: REACT_APP_SEGMENT_API_KEY })
    } else {
      console.warn('REACT_APP_SEGMENT_API_KEY envvar is not set - analytics will fail')
    }
    loaded = true
  }
  return _analytics
}

export default useSegmentAnalytics
