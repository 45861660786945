import React from 'react'

import './index.css'

interface Props {
  children: Children
  ordered?: boolean
}
interface SubComponent {
  Item: typeof Item
}
const List: React.FC<Props> & SubComponent = ({ children, ordered }) => {
  if (ordered) {
    return <ol className="List">{children}</ol>
  } else {
    return <ul className="List">{children}</ul>
  }
}

const Item: React.FC<ChildrenOnly> = ({ children }) => {
  return <li className="ListItem">{children}</li>
}

List.Item = Item

export default List
