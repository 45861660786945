import React from 'react';

import './index.css';

/**
 * HTML <form> wrapper. This adds (placeholders for) basic styling attributes,
 * plus the `noValidate` attribute to disable the default browser behavior.
 */
interface Props {
    autoComplete?: 'on' | 'off'
    children: Children
    classes?: string
    onSubmit: React.FormEventHandler<HTMLFormElement>
}
const Form: React.FC<Props> = ({ autoComplete, children, classes, onSubmit }) => {
    return (
        <form className={`Form ${classes ? classes : ''}`} noValidate onSubmit={onSubmit} autoComplete={autoComplete}>
            {children}
        </form>
    );
};

export default Form;
