import { useQuery } from 'react-query';
import { postData } from 'helpers/post-data'

type AQ = Api.Queries.AnyQuery

type QueryBody<T extends AQ['name']> = Extract<AQ, { name: T }>['body']
type QueryResult<T extends AQ['name']> = Extract<AQ, { name: T }>['result']

/*
 * Wrapper to call the "new" APIs in a more straightforward hook. At present,
 * this will return undefined if the API hasn't completed (or otherwise fail).
 * In the future, it may throw on errors.
 *
 * The general pattern is to do something like this:
 *
 * const myResult = useQuery('/api/foo/bar', { args: 'if needed' })
 * if (!myResult) { return <Loader /> }
 * // myResult is now a type-safe response
 */
const useApiQuery = <T extends AQ['name']>(
  name: T,
  body: QueryBody<T>,
): QueryResult<T>|undefined => {

  const result = useQuery(
    [name, body],
    () => postData(name, body)
  )
  if (result.isSuccess) {
    return result.data.data
  }
  return undefined
}

export default useApiQuery
