const hasCurrency = (money: Money): boolean => money.currency !== 'XXX'
const isNoCurrency = (money: Money): boolean => money.currency === 'XXX'
const isZero = (money: Money): boolean => money.amount === '0'

// For passing into e.g. Array.filter
const nonZero = (money: Money): boolean => !isZero(money)
const nonZeroOrNull = (money: Money|null): boolean => money === null || !isZero(money)

 const filter = {
  isEmpty: <T>(value: T|null): value is null => value === null,
  money: {
    hasCurrency,
    isNoCurrency,
    isZero,
    nonZero,
    nonZeroOrNull,
  },
  notEmpty: <T>(value: T|null): value is T => value !== null,
}
export default filter
