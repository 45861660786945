import { useState } from 'react'
import {
  Button,
  EmailField,
  Form,
  Heading,
  Link,
  P,
  Section,
} from 'components'
import { readError } from 'helpers'
import { useAppWideState, useFormField } from 'hooks'
import { builder } from 'routes'

import { postData } from 'helpers/post-data'
import BaseLayout from 'blocks/layout/base-layout'

enum State {
  EnterData,
  Sending,
  Sent,
}

const RequestPasswordReset: React.FC = () => {
  const { showError, showMessage } = useAppWideState()
  const [state , setState] = useState(State.EnterData)

  const email = useFormField('')
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setState(State.Sending)
    try {
      await postData('/api/auth/password/send-reset', { email })
      showMessage('Message sent!')
      setState(State.Sent)
    } catch (error) {
      setState(State.EnterData)
      showError(readError(error, 'We were unable to send your password reset link'))
    }
  }

  if (state === State.Sent) {
    return (
      <BaseLayout>
        <Section>
          <Heading>Password reset link sent</Heading>
          <P>Check your email for a message from us.</P>
          <P>Feel free to close this tab.</P>
        </Section>
      </BaseLayout>
    )
  }

  const disabled = !email.isValid || state === State.Sending

  return (
    <BaseLayout>
      <Section>
        <Heading>Reset your password</Heading>
        <Form onSubmit={onSubmit}>
          <P>We will email you a link to reset your password.</P>
          <EmailField
            binding={email}
            intent="contact"
            required
          />
          <Button type="submit" text={state === State.Sending ? 'Sending...' : 'Submit'} disabled={disabled} />
        </Form>
        <P centered>Need to sign in instead? <Link to={builder.site.signIn}>Tap here</Link></P>
      </Section>
    </BaseLayout>
  )
}

export default RequestPasswordReset
