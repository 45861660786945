import React from 'react'

import { Money, Subhead } from 'components'

import './index.css'

interface Props {
  breakdown: Api.Breakdown
}
const PaymentBreakdown: React.FC<Props> = ({ breakdown }) => {
  return (
    <div className="PaymentBreakdown">
      <p className="font-bold">Payment Details</p>

      <dl>
        <dt>Donation Amount:</dt>
        <dd>
          <Money amount={breakdown.recipientAmount} highlighted />
        </dd>
      </dl>

      <dl>
        <dt>Group Subscription:</dt>
        <dd>
          <Money amount={breakdown.platformFee} />
        </dd>
      </dl>

      <dl>
        <dt>Processing:</dt>
        <dd>
          <Money amount={breakdown.processingCost} />
        </dd>
      </dl>

      <hr/>

      <dl className="Total">
        <dt>Total Monthly Payment:</dt>
        <dd className="font-semibold">
          <Money amount={breakdown.chargedToPaymentMethod} />
        </dd>
      </dl>
    </div>
  )
}

export default PaymentBreakdown
