import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Link } from 'components'
import { useAppWideState } from 'hooks'
import { builder } from 'routes'

import NavMenu from './NavMenu'

import iconNavBack from 'assets/svg-icons/icon-nav-back.svg'
// import logo from 'assets/images/logo.svg'
// import logo from 'assets/images/logo.png'
import logo from 'assets/images/logo-noshadow.svg'

interface Props {
  showNavigation?: boolean
  showNavigationBackButton?: boolean
}
const MainHeader: React.FC<Props> = ({ showNavigationBackButton, showNavigation }) => {
  const navigate = useNavigate()
  const { isSignedIn } = useAppWideState()

  return (
    <header id="MainHeader">
      <div id="MainHeaderBackButton">
        {showNavigationBackButton && (
          <button type="button" onClick={() => navigate(-1)}>
            <img src={iconNavBack} alt="Back" />
          </button>
        )}
      </div>

      <div id="MainHeaderLogo">
        <Link to={isSignedIn ? builder.group.all : builder.site.home}>
          <img src={logo} alt="AutoGive logo" style={{maxWidth: 200}}/>
        </Link>
      </div>

      <div id="MainHeaderNav">
        {showNavigation && <NavMenu />}
      </div>
    </header>
  )
}

export default MainHeader
