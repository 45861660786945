// import React, { useEffect, useState } from 'react'
// import { addNumCommas } from 'helpers'
import { Money } from 'components'
import './index.scss'

interface Props {
  goal: Money
  donated: Money
  name: string
}

const ProgressBar: React.FC<Props> = ({ goal, donated, name })  => {
  // const [isGoal, setIsGoal] = useState('')

  return (
    <section id="groupGoalProgress">
      <div className="font-bold color-primary"><Money amount={donated} /> of <Money amount={goal} /> per month</div>
      <progress value={donated.amount} max={goal.amount}></progress>
      <span className="font-w-500">for {name}</span>
    </section>
  )
}

export default ProgressBar
