import { Money } from 'data'
import { filter } from 'helpers'

/**
 * Reduce an array of Money objects by summing them.
 *
 * All elements must be of the same currency.
 *
 * Caution: NEVER use this without a default value in reduce, or you'll get
 * a TypeError at runtime if the array to reduce is empty.
 *
 * BAD:
 * const sum = arrayOfMoney.reduce(reduce.money.sum)
 *
 * GOOD:
 * // zero can (and should) be Money.zero
 * const sum = arrayOfMoney.reduce(reduce.money.sum, zero)
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Reduce_of_empty_array_with_no_initial_value
 */
const sum = (total: Money, next: Money): Money => {
  // Common case: recipient's membership is {0/XXX}
  if (filter.money.isNoCurrency(next)) {
    return total
  }
  // Common case: {0/XXX} was passed to a default instead of zeroWithCurrency(..)
  if (filter.money.isZero(total)) {
    return next
  }

  Money.assertSameCurrency(total, next)
  return {
    amount: (parseInt(total.amount, 10) + parseInt(next.amount, 10)).toString(),
    currency: total.currency,
  }
}

const reduce = {
  hasAmount: {
    sum: <T extends { amount: Money|null }>(total: Money, next: T): Money => {
      if (next.amount === null) {
        return total
      }
      return sum(total, next.amount)
    },
  },
  money: {
    sum,
  }
}

export default reduce
