import React, { useEffect, useRef } from 'react'

import { Token } from '@stripe/stripe-js'

import {} from 'components'
import { useFormField, usePageFlow } from 'hooks'
import { postData } from 'helpers/post-data';
import { groupIdInUrl, mustBeSignedIn } from 'routes'

import './index.css'
import ConfirmBank from './ConfirmBank'
import EnterPII from './EnterPII'
import EnterBank from './EnterBank'
import ThankYou from './ThankYou'

import BaseLayout from 'blocks/layout/base-layout'

enum Page {
  EnterPII = 'personal',
  EnterBank = 'bank',
  ConfirmBank = 'confirm',
  Complete = 'done',
}
interface Props {
  groupId: GroupId
}
const KYC: React.FC<Props> = ({ groupId }) => {
  const [page, updatePage] = usePageFlow<Page>(Page.EnterPII)

  const dobMonth = useFormField('')
  const dobDay = useFormField('')
  const dobYear = useFormField('')
  const legalName = useFormField('')
  const taxId = useFormField('')
  const line1 = useFormField('')
  const line2 = useFormField('')
  const city = useFormField('')
  const state = useFormField('')
  const postalCode = useFormField('')
  const token = useRef<Token|null>(null)

  useEffect(() => {
    if (page === Page.EnterPII) {
      postData('/api/analytics/track', { funnel: "BANK_ADD", event: "APP_BA_PERSONAL", url: window.location.href, groupId: groupId })
    } else if (page === Page.EnterBank) {
      postData('/api/analytics/track', { funnel: "BANK_ADD", event: "APP_BA_BANKING", url: window.location.href, groupId: groupId })
    } else if (page === Page.ConfirmBank) {
      postData('/api/analytics/track', { funnel: "BANK_ADD", event: "APP_BA_CONFIRM", url: window.location.href, groupId: groupId })
    }
  }, [page, groupId])


  const submit = async () => {
    await postData(`/api/group/updateKyc`, {
      groupId,
      bankToken: token.current!.id,
      legalName,
      dobDay,
      dobYear,
      dobMonth,
      taxId,
      line1,
      line2,
      postalCode,
      city,
      state,
      country: 'US',
    })
  }

  if (page === Page.EnterPII) {
    return <EnterPII
      {...{
        dobDay,
        legalName,
        taxId,
        line1,
        line2,
        city,
        postalCode,
        state,
        dobMonth,
        dobYear,
      }}
      nextPage={() => updatePage(Page.EnterBank)}
    />
  } else if (page === Page.EnterBank) {
    return <EnterBank
      name={legalName}
      prevPage={() => updatePage(Page.EnterPII)}
      nextPage={() => updatePage(Page.ConfirmBank)}
      token={token}
    />
  } else if (page === Page.ConfirmBank) {
    if (token.current === null) {
      throw new Error('Bank token missing, please try again')
    }
    return <ConfirmBank
      nextPage={() => updatePage(Page.Complete)}
      prevPage={() => updatePage(Page.EnterBank)}
      submit={submit}
      token={token.current!}
      />
  } else if (page === Page.Complete) {
    return <ThankYou groupId={groupId} />
  }
  return (
    null
  )
}

// Extract route info to component property
const Wrapper: React.FC<Props> = ({ groupId }) => {
  return (
    <BaseLayout showNavigation>
      <KYC groupId={groupId} />
    </BaseLayout>
  )
}

export default mustBeSignedIn(groupIdInUrl(Wrapper))
