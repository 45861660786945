// import { useApiQuery } from 'hooks'
import { useQuery } from 'react-query';
import { postData } from 'helpers/post-data'

// This is super lame to reinvent from api.d.ts but it's good enough to unblock
type EQ<Name extends string, Id, Entity> = { name: Name, id: Id, entity: Entity }
type EntityQueries =
  | EQ<'group', GroupId, Api.Entity.Group>
  | EQ<'paymentMethod', PaymentMethodId, Api.Entity.PaymentMethod>
  | EQ<'subscription', SubscriptionId, Api.Entity.Subscription>
  | EQ<'user', UserId, Api.Entity.User>

type ReadId<T extends EntityQueries['name']> = Extract<EntityQueries, { name: T }>['id']
type ReadType<T extends EntityQueries['name']> = Extract<EntityQueries, { name: T }>['entity']

const useEntity = <T extends EntityQueries['name']>(name: T, id: ReadId<T>|null|undefined): ReadType<T>|undefined => {
  let url: Api.Queries.AnyQuery['name']
  let body: DictOf<any>
  switch (name) {
    case 'group':
      url = '/api/group/entity'
      body = { groupId: id } as const
      break
    case 'paymentMethod':
      url = '/api/paymentMethod/entity'
      body = { paymentMethodId: id } as const
      break
    case 'subscription':
      url = '/api/subscription/entity'
      body = { subscriptionId: id } as const
      break
    case 'user':
      url = '/api/user/entity'
      body = { userId: id } as const
      break
    default:
      const exhaustivenessCheck: never = name
      throw new Error(`Hit default case ${exhaustivenessCheck} in exhaustive switch`)
  }

  const result = useQuery([url, body], () => postData(url, body), {
    enabled: !!id,
  })

  if (result.isSuccess) {
    return result.data.data
  }
  return undefined
}

export default useEntity
