import React from 'react'

import Footer from './Footer'
import MainHeader from './MainHeader'
import Messages from './Messages'
import classNames from 'classnames';

import './index.scss'

/**
 * THIS IS STILL USING OLD STYLES
 */
interface Props {
  children: Children
  showNavigation?: boolean
  showNavigationBackButton?: boolean
  groupHome?: boolean
  hasSections?: boolean
}
const BaseLayout: React.FC<Props> = ({
  children,
  showNavigationBackButton = false,
  showNavigation = false,
  groupHome,
  hasSections
}) => {
  return (
    <div id="BaseLayout" className="modern">
      <MainHeader
        showNavigation={showNavigation}
        showNavigationBackButton={showNavigationBackButton}
      />

      <Messages />

      <main className={classNames('main', { groupHome, hasSections })}>
        {children}
      </main>

      <Footer />
    </div>
  )
}

export default BaseLayout
