export { default as cookies } from './cookies'
export { default as filter } from './filter'
export { default as formatPhoneNumber } from './formatPhoneNumber'
export { default as pluralize } from './pluralize'
export { default as reduce } from './reduce'
export { default as showConfetti } from './showConfetti'
export { default as sort } from './sort'
export { default as toSnakeCase } from './toSnakeCase'
export { default as validate } from './validate'
export { getFirstName } from './name'

const readError = (error: any, defaultMessage: string): string => {
  if (error instanceof Error && error.message) {
    return error.message
  }
  return defaultMessage
}
export { readError }
