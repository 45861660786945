import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ErrorBoundary } from 'react-error-boundary'

import routes from 'routes/routes'
import { AppWideState } from 'contexts/appWideState'
import { Error as ErrorPage } from 'pages'
import { useSegmentAnalytics } from 'hooks'
import * as config from 'config'

const stripePromise = loadStripe(config.REACT_APP_STRIPE_KEY)
// const googleClientId = config.REACT_APP_GOOGLE_CLIENT_ID

const App = () => {
  const { pathname, hash } = useLocation()

  const segment = useSegmentAnalytics()

  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTo(0, 0)

    // Trigger analytics event when page/path changes (mostly POC
    // integration)
    segment?.page()
  }, [segment, pathname, hash])


  return (
    <AppWideState>
      <Elements stripe={stripePromise}>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <Routes>
            {routes.map((route, idx) => (
              <Route key={idx} {...route} />
            ))}
          </Routes>
        </ErrorBoundary>
      </Elements>
    </AppWideState>
  )
}

export default App
