import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import { AnchorButton, UserText } from 'components'

import './index.css'

enum State {
  Open,
  Closed,
  FixedOpen,
}

/**
 *  Component to show/hide long text. This is intended to display user-provided
 * content, so exercise necessary caution around formatting (for now, it only
 * uses some line break preservation CSS, which probably covers 95% of what we
 * want anyway).
 *
 * Future improvements:
 * - Add CSS transitions on toggling
 */
interface Props {
  message: string
}
const OverflowableMessage: React.FC<Props> = ({ message }) => {
  const [state, setState] = useState(State.Closed)
  const overflowRef = useRef<HTMLDivElement>(null)

  const toggleOpen = () => {
    setState(prev => {
      // No toggle if fixed
      if (prev === State.FixedOpen) {
        return prev
      }
      return prev === State.Open ? State.Closed : State.Open
    })
  }

  const isOpen = state === State.Open

  useEffect(() => {
    const measureTextSize = () => {
      if (!overflowRef.current) {
        return
      }
      // If open, the overflow detection will be wrong. Do not re-measure.
      if (isOpen) {
        return
      }
      const { clientHeight, scrollHeight } = overflowRef.current
      const isOverflowing = scrollHeight > clientHeight + 30
      if (isOverflowing) {
        setState(State.Closed)
      } else {
        setState(State.FixedOpen)
      }
    }
    measureTextSize()
    // I saw this suggested, but since the overflow is based on line height I'm
    // not sure it's necessary.
    // window.addEventListener('resize', measureTextSize)
    // return () => window.removeEventListener('resize', measureTextSize)
  }, [isOpen, message])


  return (
    <div className={classNames('OverflowableMessage', { open: isOpen, closed: !isOpen })}>
      <div className={`OverflowableMessageBody ${state !== State.FixedOpen && 'has-more'}`} ref={overflowRef}>
        <UserText text={message} />
      </div>
      {state !== State.FixedOpen &&
      <div className="OverflowableMessageOpenCloseLink">
        <AnchorButton onClick={toggleOpen}>Show {isOpen ? 'less' : 'more'}</AnchorButton>
      </div>
      }
    </div>
  )
}

export default OverflowableMessage
