import { useEffect, useState } from 'react'

/**
 * Improves the handling of stateful pages that visually look like multuple
 * pages but for (typically) state managmeent reasons want to keep everything
 * in a single URL. Common example: a form with a confirmation/review screen
 * before submission.
 *
 * This takes an enum value and puts it in the URL for other benefits:
 * - browser navigation controls work better
 * - our SPA navigation scroll-to-top hook runs
 * - maybe long-term state management and restoration improvements?
 *
 * Due to limitations in Typescript, you need to specify the enum at the call
 * site; e.g.
 * ```
 * const [page, updatePage] = usePageFlow<Page>(Page.Landing)
 * ```
 *
 * Note: by design, this does not restore page state on initial page load. This
 * is intended for SPA navigation, and starting mid-flow with empty other state
 * will lead to unintended effects.
 */
const usePageFlow = <T extends string>(initialPage: T): [T, (newPage: T) => void] => {
  const [page, setPage] = useState(initialPage)

  useEffect(() => {
    // On initial page load, set the fragment to the initial page. Use direct
    // history API to ensure the back button doesn't get flaky.
    history.replaceState(null, '', '#' + initialPage) // eslint-disable-line no-restricted-globals

    // When the hash changes (updatePage OR native navigation controls), update
    // the state to trigger the re-renders.
    const handleHashChange = () => {
      setPage(getPageFromUrl<T>())
    }

    window.addEventListener('hashchange', handleHashChange)
    return () => window.removeEventListener('hashchange', handleHashChange)
  }, [initialPage])

  return [page, updatePage]
}

// URL tracking - put the value in the location hash. This treats the hash as
// the canonical place.
const updatePage = <T extends string>(newPage: T) => {
  window.location.hash = newPage
}

// Read it back out of the URL
const getPageFromUrl = <T extends string>(): T => {
  return window.location.hash.slice(1) as T // remove leading #
}

export default usePageFlow
