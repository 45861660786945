import React from 'react'

import './index.css'

interface Props {
  data: DictOf<Children>
}
const KeyValueList: React.FC<Props> = ({ data }) => {
  return (
    <dl className="KeyValueList">
      {Object.keys(data).filter(key => data[key] !== undefined).map(key => (
        <div key={key}>
          <dt>{key}</dt>
          <dd>{data[key]}</dd>
        </div>
      ))}
    </dl>
  )
}

export default KeyValueList
