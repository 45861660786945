// import React from 'react'
import { Link as RRLink, useLocation } from 'react-router-dom'

import { builder } from 'routes'

import './index.scss'

/**
 * Special-cased Link to maintain history state after signing in. Sadly this
 * duplicates most of that component, but we really want to avoid exposing
 * react-router internals (especially page state) to our broader components.
 */
interface Props {
  defaultMessage?: string
}
const ContactLink: React.FC<Props & HasChildren> = ({ children, defaultMessage = ''}) => {
  const location = useLocation()
  let state: ContactFormState = {
    afterContactUrl: location.pathname + location.search + location.hash,
    defaultMessage,
  }
  return (
    <RRLink
      className="ContactLink"
      state={state}
      to={builder.site.contact}
    >{children}</RRLink>
  )
}

export default ContactLink
