import React, { useEffect, useState } from 'react'

import { Loader, P, Subhead } from 'components'
import { Money } from 'data'
import { useReloadApiQuery } from 'hooks'

import MPF, { PayerAndPaymentMethodData } from 'components/PaymentForm/ModalPaymentForm'
import { postData } from 'helpers/post-data'

enum UpdateState {
  NotStarted,
  Started,
  Succeeded,
  Failed,
}
interface Props {
  amount: Money
  groupId: GroupId
  subscriptionId: SubscriptionId
}
const ChangePaymentMethod: React.FC<Props> = ({ amount, groupId, subscriptionId }) => {

  const reloadSubscription = useReloadApiQuery('/api/subscription/entity', { subscriptionId })

  const [breakdown, setBreakdown] = useState(Money.createBreakdown(amount.currency))

  const [updating, setUpdating] = useState(UpdateState.NotStarted)

  // the amount isn't really needed beyond the AP UI. Maybe it SHOULD be zero?
  useEffect(() => {
    const calculate = async () => {
      const res = await postData('/api/fees/calculate', { amount })
      const bd = res.data as Api.Breakdown
      setBreakdown(bd)
    }
    calculate()
  }, [amount])

  const onPaymentSubmit = async (data: PayerAndPaymentMethodData) => {
    setUpdating(UpdateState.Started)
    try {
      await postData('/api/subscription/changePaymentMethod', {
        subscriptionId,
        token: data.paymentMethodId,
      })
      setUpdating(UpdateState.Succeeded)
      return true
    } catch (error) {
      setUpdating(UpdateState.Failed)
      console.error('ChangePaymentMethod failed', error)
      return false
    } finally {
      reloadSubscription()
      setTimeout(() => {
        setUpdating(UpdateState.NotStarted)
      }, 10000)
    }
  }

  let actionOrStatus: React.ReactNode
  switch (updating) {
    case UpdateState.Started:
      actionOrStatus = <Loader />
    break
    case UpdateState.Succeeded:
      actionOrStatus = <P>Your card has been updated!</P>
    break
    case UpdateState.Failed:
      actionOrStatus = <P><span style={{ color: 'var(--error)' }}>Your card could not be updated.</span></P>
    break
    case UpdateState.NotStarted:
     // the figma copy doesn't make any sense unless changing the amount, which we don't do yet.
    // <P>Your existing payment will be replaced with the new monthly payment that you'll create now.</P>
      actionOrStatus = <>
        <P><strong>Your new card will not be charged until your next payment.</strong></P>
        <MPF
          buttonText="Change Card"
          collectPayerEmail={false}
          collectPayerName={false}
          collectPayerPhone={false}
          disabled={false}
          breakdown={breakdown}
          onPaymentSubmit={onPaymentSubmit}
        />
      </>
    break
  }

  return <>
    <Subhead>Change Card?</Subhead>
    {actionOrStatus}
  </>
}

export default ChangePaymentMethod
