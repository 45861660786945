import { TextField } from 'components'
import { formatPhoneNumber } from 'helpers'

// Intent can be used to semantically modify the surrounding copy/text/labels
type Intent =
  | 'contact'
  | 'inviteRecipient'
  | 'signin'
  | 'register'
  | 'update'

interface Props extends FormFieldProps<string> {
  errorMessage?: React.ReactNode
  intent: Intent
}
const PhoneNumberField: React.FC<Props> = ({
  binding,
  errorMessage,
  inline,
  intent,
  name,
  required
}) => {
  // TODO: reintroduce invalid ph#

  let placeholder = 'Your Mobile Number'
  if (intent === 'inviteRecipient') {
    placeholder = 'Recipient Mobile Number'
  }

  const onBlur = () => {
    // Make no attempt on stuff that fails the basic regex (filters letters and
    // weird punctuation)
    if (!binding.isValid) {
      return
    }

    // If it looked remotely phone-esque, continue by grabbing the digits.
    const digits = binding.value.replace(/\D/g, '')

    // This is very US-centric and should get eventually replaced with
    // something better.
    if (digits.length === 10 || (digits.length === 11 && digits[0] === '1')) {
       // ok
      binding.setValue(formatPhoneNumber(digits))
    } else {
      binding.setIsValid(false)
    }
  }

  let validationMessage = binding.value === ''
    ? 'Mobile number is required'
    : 'Invalid or non-US phone number'

  return (
    <TextField
      autoComplete="tel"
      pattern="\+?[0-9\(\) .\-]{10,}" // optional + followed by at least 10 numbers and phone punctuation (partial)
      type="tel"
      {...{ binding, errorMessage, inline, name, onBlur, placeholder, required, validationMessage }}
    />
  )
}

export default PhoneNumberField
