import React, { useEffect, useState } from 'react'

import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'

import {
  Button,
  Form,
  Heading,
  Icon,
  Link,
  Loader,
  P,
  TextArea,
} from 'components'
import { NavigationTarget } from 'data'
import { useApiQuery, useFormField, useRedirect } from 'hooks'
import { builder, groupIdInUrl, mustBeSignedIn } from 'routes'

interface Props {
  groupId: GroupId
}
const SetWelcomeNote: React.FC<Props> = ({ groupId }) => {
  const note = useFormField('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const redirect = useRedirect()

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    setIsSubmitting(false)
    await postData('/api/group/editWelcomeNote', { groupId, note })
    // clear local storage?

    redirect(new NavigationTarget({
      url: builder.group.view(groupId),
    }))
  }

  const groupData = useApiQuery('/api/group/memberOnlyData', { groupId })
  const noteSetValue = note.setValue
  useEffect(() => {
    if (groupData) {
      noteSetValue(groupData.welcomeNote)
    }
  }, [groupData, noteSetValue])

  if (!groupData) {
    return <Loader />
  }

  const disabled = !note.isValid || isSubmitting
  return (<BaseLayout showNavigation showNavigationBackButton>
    <div id="SetWelcomeNote">
      <Form onSubmit={onSubmit}>
        <Heading>Welcome Note</Heading>

        <P>Write a Welcome Note that best describes your group’s purpose.</P>

        <P>
          <Icon icon="lock" size="small" inline />
          Only members you allow into your group will see this note.
        </P>

        <TextArea
          binding={note}
          placeholder="Please describe why you are collecting money for this group..."
          required
          validationMessage="A welcome note is required"

        />
        <Button type="submit" text="Save" disabled={disabled} />

        {groupData.welcomeNote !== '' && (
          <P centered><Link to={builder.group.view(groupId)}>Cancel</Link></P>
        )}
      </Form>
    </div>
  </BaseLayout>)
}

export default mustBeSignedIn(groupIdInUrl(SetWelcomeNote))
