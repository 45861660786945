import React from 'react'

import { Button, Form, Heading, Link, P, PasswordField, Section } from 'components'
import { NavigationTarget } from 'data'
import { useAppWideState, useFormField, useRedirect } from 'hooks'
import { builder, mustBeSignedIn } from 'routes'

import { postData } from 'helpers/post-data'
import BaseLayout from 'blocks/layout/base-layout';

const ChangePassword: React.FC = () => {
  const currentPassword = useFormField('')
  const newPassword = useFormField('')
  const confirm = useFormField('')
  const { showMessage } = useAppWideState()
  const redirect = useRedirect()

  const disabled = !currentPassword.isValid
  || !newPassword.isValid
  || confirm.value !== newPassword.value

  console.debug('cp', currentPassword.isValid, 'np', newPassword.isValid, 'cn', confirm.isValid)

  const onSubmit = async (e: React.FormEvent) => {
    // track changing state?
    e.preventDefault()
    const result = await postData('/api/auth/password/change', { currentPassword, newPassword })
    console.debug(result)
    showMessage('Password changed')
    redirect(new NavigationTarget({
      url: builder.user.settings,
    }))
  }
  return (
    <BaseLayout showNavigationBackButton showNavigation>
      <Section>
        <Form onSubmit={onSubmit}>
          <Heading>Change Password</Heading>
          <PasswordField
            binding={currentPassword}
            name="currentPassword"
            type="current"
          />

          <P>Your new password must be at least 8 letters long, including one number and symbol.</P>

          <PasswordField
            binding={newPassword}
            name="newPassword"
            type="new"
          />

          <PasswordField
            binding={confirm}
            name="confirmNewPassword"
            type="confirm-new"
            errorMessage={newPassword.value !== confirm.value && "Passwords do not match"}
          />

          <Button type="submit" disabled={disabled} text="Change password" />

        </Form>

        <P centered><Link to={builder.site.requestPasswordReset}>I forgot my password</Link></P>

        {/* <P centered>Want to sign out everywhere?</P> */}
      </Section>
    </BaseLayout>
  )
}

export default mustBeSignedIn(ChangePassword)
