// TODO: turn this into a more widely reusable component
import React, { useEffect } from 'react'

import { NavigationTarget } from 'data'
import { useApiQuery, useRedirect } from 'hooks'
import { builder } from 'routes'

interface Props {
  groupId: GroupId
  children: Children
}
const AuthGuard: React.FC<Props> = ({ groupId, children }) => {
  const redirect = useRedirect()
  const roleData = useApiQuery('/api/group/me', { groupId })

  // This is a bit annoying - redirect is only supposed to be called from
  // inside an effect. We also can't call the effect or other hooks
  // conditionally because of Rules of Hooks. Consequently, the logic gets
  // duplicated inside and outside of the effect.
  //
  // I'm sure there's a better way to do this; we'll revisit as the API
  // stabilizes.
  useEffect(() => {
    if (!roleData) {
      return
    }
    if (!roleData.permissions.view) {
      if (roleData.roles.isPending) {
        redirect(new NavigationTarget({
          url: builder.group.waitingForApproval(groupId),
        }))
      } else {
        redirect(new NavigationTarget({
          url: builder.group.memberLanding(groupId),
        }))
      }
    }
  }, [roleData, groupId, redirect])

  if (!roleData) {
    return null // loader??
  }
  if (!roleData.permissions.view) {
    // Avoid an early failed render in the blip between receiving the result
    // and the effect triggering the redirect.
    return null
  }

  return <>{children}</>
}

export default AuthGuard
