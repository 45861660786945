import React, { useEffect, useState } from 'react'
import BaseLayout from 'blocks/layout/base-layout';

import {
  Sections,
  Loader, 
  Link
} from 'components'
import { builder, groupIdInUrl, mustBeSignedIn } from 'routes'
import { useApiQuery } from 'hooks'

import './index.scss'

import AuthGuard from './AuthGuard'
import GroupSelector from './GroupSelector'
import Members from './Members'
import Messages from './Messages'
import Overview from './Overview'
import WelcomeNote from './WelcomeNote'

interface Props {
  groupId: GroupId
}
const GroupHome: React.FC<Props> = ({ groupId }) => {
  const [loaded, setLoaded] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    selector: true,
    overview: true,
    // welcome: true,
    members: true
  })

  const setLoading = (componentKey: string, isLoading: boolean) => {
    setLoadingStates(prev => ({ ...prev, [componentKey]: isLoading }))
  }

  // Check if all components are loaded
  useEffect(() => {
    const allLoaded = Object.values(loadingStates).every(state => state === false)
    if (allLoaded) {
      setLoaded(true)
    }
  }, [loadingStates])

  return (
    <BaseLayout showNavigation showNavigationBackButton groupHome>
      {!loaded && <Loader />}
      <AuthGuard groupId={groupId}>
        <div id="GroupHomeV2" style={{ display: loaded ? 'flex' : 'none' }}>
          <GroupSelector selectedGroupId={groupId} onLoadingChange={(isLoading: boolean) => setLoading('selector', isLoading)}  />

          <Overview groupId={groupId} onLoadingChange={(isLoading: boolean) => setLoading('overview', isLoading)} />

          {/*
            <WelcomeNote groupId={groupId} onLoadingChange={(isLoading: boolean) => setLoading('welcome', isLoading)} /> 
            Hiding UI for now
          */}    
                
          <Members groupId={groupId} onLoadingChange={(isLoading: boolean) => setLoading('members', isLoading)} />

          <Messages groupId={groupId} />          

          <Sections.CreateAnotherGroup />          
        </div>
      </AuthGuard>
    </BaseLayout>
  )
}

export default mustBeSignedIn(groupIdInUrl(GroupHome))
