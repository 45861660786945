import { Money } from 'components'

type ErrorMessageData = {
    min?: Money
    max?: Money
    required?: boolean
}
const makeValidationErrorMessage = ({ min, max, required }: ErrorMessageData): React.ReactNode => {
    if (min && max) {
        return <>Must be between <Money amount={min} /> and <Money amount={max} /></>
    } else if (min) {
        return <>Must be at least <Money amount={min} /></>
    } else if (max) {
        return <>Must be at most <Money amount={max} /></>
    } else if (required) {
        return 'Amount is required';
    } else {
        return undefined
    }
}

export default makeValidationErrorMessage
