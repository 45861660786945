import React from 'react'

import BaseLayout from 'blocks/layout/base-layout';

import {
  Heading,
  KeyValueList,
  Link,
  List,
  Loader,
  Money,
  Subhead,
  Time,
} from 'components'
import { reduce, sort } from 'helpers'
import { Money as MoneyHelper } from 'data'
import { useApiQuery } from 'hooks'
import { builder, mustBeSignedIn } from 'routes'

import './index.css'

const UserPaymentHistory: React.FC = () => {
  const payments = useApiQuery('/api/payment/mine', undefined)
  if (!payments) {
    return <Loader />
  }

  // CAUTION: this will absolutely break once you can donate in multiple
  // currencies

  const allTimeTotal = payments.reduce(reduce.hasAmount.sum, MoneyHelper.zero)
  return (
    <BaseLayout showNavigationBackButton showNavigation>
      <div id="UserPaymentHistory">
        <Heading>Payment History</Heading>
        <Subhead>My gifts to all groups</Subhead>
        <KeyValueList data={{
          Payments: payments.length,
          'Lifetime Total': <Money amount={allTimeTotal} highlighted />
        }} />

        <List ordered>
          {payments.sort(sort.createdNewestFirst).map(payment => (
            <PaymentRow key={payment.id} payment={payment} />
          ))}
        </List>
      </div>
    </BaseLayout>
  )
}

interface PRProps {
  payment: Api.Entity.Payment
}
const PaymentRow: React.FC<PRProps> = ({ payment }) => {
  const group = useApiQuery('/api/group/entity', { groupId: payment.groupId })
  return (
    <List.Item>
      <Time unixtimeSeconds={payment.createdAt} />
      <br />
      <Money amount={payment.amount!} highlighted />
      <> &ndash; </>
      <Link to={builder.group.view(payment.groupId)}>{group?.recipientFullName}</Link>
    </List.Item>
  )
}

export default mustBeSignedIn(UserPaymentHistory)
