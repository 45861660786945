import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppWideState } from 'hooks'
import { builder } from 'routes'

/**
 * This creates a HoC that requires authentication. If there is no user signed
 * in, it will redirect to the sign in page, providing state to that page so
 * that the user can be sent back to the original page.
 */
const mustBeSignedIn = (Component: React.FC) => {
  return () => {
    const nav = useNavigate()
    const location = useLocation()
    const { isSignedIn } = useAppWideState()

    useEffect(() => {
      if (!isSignedIn) {
        nav(builder.site.signIn, {
          state: {
            afterSignIn: location.pathname + location.search + location.hash,
          } as SignInState,
        })
      }
    }, [isSignedIn, nav, location.pathname])

    return (
      <Component />
    )
  }
}

export default mustBeSignedIn
