import React from 'react'

import { ContactLink, Heading, Anchor, Link, List, Loader, P } from 'components'
import { useApiQuery } from 'hooks'
import { builder, groupIdInUrl, mustBeSignedIn } from 'routes'
import './index.scss'

import BaseLayout from 'blocks/layout/base-layout';

const FAQ: React.FC = () => {
  return (
    <BaseLayout showNavigationBackButton showNavigation>
      <Heading>Common Questions</Heading>

      <article className="faqWrap"> 
        <section>
          <h4>First, some terms we use...</h4>
          <div><strong>Group Organizer:</strong><br/>Person who creates the group.</div><br/>
          <div><strong>Recipient:</strong><br/>Person receiving money from the group.</div><br/>          
          <div><strong>Group Members:</strong><br/>Invited individuals who make monthly donations to the group.</div>
        </section>

        <section>
          <h4>How do I invite people to my group?</h4>
          <div>AutoGive makes it easy to compose your own invitation via text message or email from your own device. This means you won’t need to remember everyone’s contact info.</div>
        </section>    

        <section>
          <h4>How do members of my group make a monthly donation?</h4>
          <div>Simple! We support Apple Pay, Google Pay, or using a credit / debit card to make a monthly donation to your group.</div>
        </section> 
        
        <section>
          <h4>How do I, or someone I invite actually receive the money?</h4>
          <div>When you create your group, we’ll ask you for the name and contact info of the person who will be receiving the monthly donations (even if it’s you).<br/><br/>Then, the Recipient of the money will be invited to securely add their banking info so they can begin to receive a weekly deposit. The total of all weekly deposits is equal to the amount of donations that the group collects each month.</div>
        </section>

        <section>
          <h4>Does the Recipient of the money get charged any fees?</h4>
          <div><strong>No! The Recipient recieves 100% of a Group Member’s monthly donation.</strong> To make this possible, Group Members cover the payment processing, payout and platform subscription fees.</div>
        </section> 

        <section>
          <h4>What fees do Group Members pay?</h4>
          <div>Group members are charged a card processing & payout fee equivalent to <strong>3.25% + $0.30</strong> of each month’s total charges.<br/><br/> AutoGive does charge each Group Member <strong>$1 per week</strong> to use the service. This small fee helps support our team and is billed as $4 per month.<br/><br/> Learn more about Fees and how your total monthly payment is calculated <strong><a href="https://webflow.autogive.com/terms#fees">here.</a></strong><br/><br/> We think you’ll find our service very helpful and the low fees well worth the price.</div>
        </section>   

        <section>
          <h4>Can I use AutoGive in my country?</h4>
          <div>The Recipient of the Group's monthly donations will need to have a valid <strong>United States Social Security Number.</strong> Support for more countries is coming soon!<br/><br/> Group Members donating monthly can be located in almost any country that uses either credit or debit cards.</div>
        </section>   

        <section>
          <h4>What about taxes?</h4>
          <div>While we’re not Certified Public Accounts (CPA) and cannot offer tax advice, receiving a financial gift/donation in the U.S. from another person is generally not considered income and is not taxed as income.<br/><br/><strong> Please consult your financial advisor to determine whether gifts received will impact your eligibility to other income or assistance programs.</strong> <br/><br/>Additionally, in the U.S. an individual can give up to $18,000 per year to another individual without requiring any tax filings. This amount increases to $36,000 per year for gifts from married couples to an individual. This is called ‘The Annual Gift Tax Exclusion’.<br/><br/>Donations made on AutoGive are considered personal gifts and are not tax deductible.</div>
        </section>   

        <section>
          <h4>How do I, or the recipient access the donations collected?</h4>
          <div>When you create your group, we’ll ask you for the name and contact information of the person who will be receiving the monthly donations (even if it’s you).<br/><br/>Then, the recipient of the money will be invited to securely add their banking details so they can begin to receive a weekly deposit. Each weekly deposits is equal to the full amount of money that the group collects that week.</div>
        </section> 

        <section>
          <h4>I still have a question…</h4>
          <div>No problem! We’d love to hear from you. <strong><a href="/contact">Contact us!</a></strong></div>
        </section>        

        <br/>                          

        <span className="TOSLinks">
          <Anchor url="https://www.autogive.com/terms" openInNewTab trusted>
            Terms &amp; Conditions
          </Anchor>
          <br/>
          <Anchor url="https://www.autogive.com/terms#fees" openInNewTab trusted>
            Autogive Fees
          </Anchor>
          <br/>
          <Anchor url="https://www.autogive.com/terms#privacy-policy" openInNewTab trusted>
            Privacy Policy
          </Anchor>
        </span>      

        <P centered>
          <strong>Need help?</strong>
          <br />
          <ContactLink>Contact Us</ContactLink>
        </P>
      </article>
    </BaseLayout>
  )
}

export default FAQ