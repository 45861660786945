import { useEffect } from 'react'

import {
  Button,
  // CustomSlider,
  Form,
  Heading,
  MoneyInput,
  P,
  Section,
} from 'components'
import { DEFAULT_CURRENCY } from 'config'
import { Money as MoneyHelper, NavigationTarget } from 'data'
import { useFormField, useRedirect } from 'hooks'
import { builder } from 'routes'

import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'
import '../index.scss'

const CreatGroupSetGoal: React.FC = () => {
  // const { isSignedIn, signIn, showError } = useAppWideState()

  const redirect = useRedirect()

  useEffect(() => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: "APP_GC_GROUP_SET_GOAL", url: window.location.href })
  }, [])

  const amount = useFormField(MoneyHelper.amountWithCurrency(1_250_00, DEFAULT_CURRENCY))

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    sessionStorage.setItem('goalAmount.amount', amount.value.amount)
    sessionStorage.setItem('goalAmount.currency', amount.value.currency)

    const url = builder.group.setGroupDetails
    redirect(new NavigationTarget({ url }))

  }

  const disabled = !amount.isValid

  return (
    <BaseLayout>
      <Section id="CreatGroupSetGoal">
        <Form onSubmit={onSubmit}>
          <Heading>Set Monthly Goal</Heading>

{/*      <CustomSlider
        min={0}
        max={1000}
        onChange={handleSliderChange}
      />
*/}
          {/*<CustomSlider />      */}
          {/*<CustomSlider min={100} max={10000} onChange={handleChange}/>*/}
          {/*min={250} max={5000} initialValue={750}*/}
          {/*<Money amount={amount} addStyleHooks />*/}
          {/*<section>${formattedNumber}</section>*/}

          {/*<MoneyBox amount={amount.value}/>*/}
          <P>Enter your monthly goal here:</P>

          <MoneyInput
            binding={amount}
            min={MoneyHelper.amountWithCurrency(5_00, DEFAULT_CURRENCY)}
            max={MoneyHelper.amountWithCurrency(100_000_00, DEFAULT_CURRENCY)}
            required
          />

          {/*<P>{constantStrings.usOnlyNote}</P>*/}
          {/*<TOSLinks />*/}
          <Button type="submit" text="Next" />
        </Form>
      </Section>
    </BaseLayout>
  )
}

export default CreatGroupSetGoal
