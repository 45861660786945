import type {
  StripeElementClasses,
  StripeElementStyle,
  StripeElementStyleVariant,
} from '@stripe/stripe-js'

// TODO: figure out how to merge these with vars/TextField styles
const stripeStyleLegacy: StripeElementStyleVariant = {
  color: '#50555c',// var(--dark)
  fontFamily: '"Open Sans", sans-serif',
  fontSize: '20px',
  fontWeight: '300',
  '::placeholder': {
    color: '#b2b2b2', // var(--placeholder)
    fontWeight: 300,
  }
}
const stripeStyleModern = {
  ...stripeStyleLegacy,
  fontSize: '19px',
}

export const stripeElementClasses: StripeElementClasses = {
  base: 'CreditCardFormStripeBase',
  complete: 'CreditCardFormStripeComplete',
  empty: 'CreditCardFormStripeEmpty',
  focus: 'CreditCardFormStripeFocus',
  invalid: 'CreditCardFormStripeInvalid',
  // webkitAutofill: 'CreditCardFormStripeBase',
}

export const stripeElementStyleLegacy: StripeElementStyle = {
  base: stripeStyleLegacy,
  empty: stripeStyleLegacy,
  complete: stripeStyleLegacy,
  invalid: stripeStyleLegacy,
}

export const stripeElementStyleModern: StripeElementStyle = {
  base: stripeStyleModern,
  empty: stripeStyleModern,
  complete: stripeStyleModern,
  invalid: stripeStyleModern,
}
