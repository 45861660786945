import React from 'react'

import './index.scss'

interface Props {
  children: Children
  footerLeading?: React.ReactNode
  footerTrailing?: React.ReactNode
  headerLeading?: React.ReactNode
  headerTrailing?: React.ReactNode
  id?: string
}

const Section: React.FC<Props> = ({
  children,
  footerLeading,
  footerTrailing,
  headerLeading,
  headerTrailing,
  id,
}) => {  
  return <section className="Section" id={id}>
    {(headerLeading || headerTrailing) && <header>
      <span className="SectionHeaderLeading">{headerLeading}</span>
      <span className="SectionHeaderTrailing">{headerTrailing}</span>
    </header>}
    {children}
    {(footerLeading || footerTrailing) && <footer>
      <span className="SectionFooterLeading">{footerLeading}</span>
      <span className="SectionFooterTrailing">{footerTrailing}</span>
    </footer>}
  </section>
}

export default Section
