import React, { useEffect } from 'react'
import classNames from 'classnames'

import {
  List,
  Money,
  Section,
} from 'components'
import { filter, pluralize, sort } from 'helpers'
import { useApiQuery } from 'hooks'
import { builder } from 'routes'

import Button from './Button'

import './Members.scss'

interface Props {
  groupId: GroupId
  onLoadingChange: (isLoading: boolean) => void
}
const Members: React.FC<Props> = ({ groupId, onLoadingChange }) => {

  const permissions = useApiQuery('/api/group/me', { groupId })
  const group = useApiQuery('/api/group/entity', { groupId })
  const members = useApiQuery('/api/group/members', { groupId })

  useEffect(() => {
    // Signal that loading is complete when members data is loaded
    if (members) {
      onLoadingChange(false);
    }
  }, [members]) 

  if (!group || !members || !permissions) {
    return null
  }

  const pendingCount = members.filter(m => !m.isApproved).length
  const payingMembers = members.filter(m => filter.money.nonZeroOrNull(m.amount))

  // onLoadingChange(false)

  return (
    <Section
      headerLeading={<h3>Group Members</h3>}
      headerTrailing={permissions.permissions.allowMembers ? (<Button
        text={`${pendingCount} Pending ${pluralize('Member', pendingCount)}`}
        url={builder.group.allowMembers(groupId)}
      />) : null}
    >
      <div className="members">
        <strong>Recipient</strong> &ndash; {group.recipientFullName}<br/ >
        <strong>Organizer</strong> &ndash; {group.organizerFullName}
      </div>

      <List>
        {payingMembers.sort(sort.amountHighestFirst).map(m => (
          <List.Item key={m.id}><MemberRow membership={m} canApprove={permissions.permissions.allowMembers} /></List.Item>
        ))}
      </List>

      {permissions.permissions.inviteMembers && <Button
        intent="primary"
        size="large"
        text="Invite Group Members Now!"
        url={builder.group.inviteContacts(groupId)}
      />}
    </Section>

  )
}

interface MRowProps {
  membership: Api.Entity.Membership
  canApprove: boolean
}
const MemberRow: React.FC<MRowProps> = ({ canApprove, membership }) => {
  return (
    <div className={classNames("MembersRow", { pending: !membership.isApproved })}>
      <span className="MembersRowAmount">{membership.amount ? <Money amount={membership.amount} addStyleHooks /> : <em>Amount private</em>}</span>
      <span className="MembersRowSeparator">{' '}&ndash;{' '}</span>
      <span className="MembersRowName">{membership.memberFullName ?? <em>Private</em>}</span>
      {canApprove && !membership.isApproved && (
        <span className="MembersRowAllow">
          <Button text="Allow?" intent="success" url={builder.group.allowMembers(membership.groupId)} />
        </span>
      )}
    </div>
  )
}

export default Members
