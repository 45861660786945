import { useEffect } from 'react'

import { TextField } from 'components'
import { validate } from 'helpers'

interface Props extends FormFieldProps<string> {
  errorMessage?: React.ReactNode
  type: 'create' | 'current' | 'new' | 'confirm-new'
}
const PasswordField: React.FC<Props> = ({ binding, errorMessage, inline, name, required, type }) => {
  const autoComplete = (type === 'current') ? 'current-password' : 'new-password'

  let minLength: number|undefined
  let placeholder: string
  let validationMessage: string = ''
  let performClientValidation = false
  switch (type) {
    case 'create':
      placeholder = 'Set Your Password'
      minLength = 8
      validationMessage = 'Must be 8 charcters including a number and symbol'
      performClientValidation = true
    break
    case 'current':
      placeholder = 'Your Password'
      // DO NOT perform client validation for current password; users will get
      // stuck entering their then-current password if we change the requirements
    break
    case 'new':
      minLength = 8
      placeholder = 'New Password'
      validationMessage = 'Must be 8 charcters including a number and symbol'
      performClientValidation = true
    break
    case 'confirm-new':
      minLength = 8
      placeholder = 'Confirm New Password'
    break
  }

  const bindingSetIsValid = binding.setIsValid
  useEffect(() => {
    if (!performClientValidation) {
      return
    }
    bindingSetIsValid(validate.password(binding.value))
  }, [performClientValidation, binding.value, bindingSetIsValid])

  // There's a (safari-only?) passwordRules attribute that... doesn't even work
  // in safari. But info is https://developer.apple.com/password-rules/

  return <TextField
    autoComplete={autoComplete}
    errorMessage={errorMessage}
    binding={binding}
    inline={inline}
    minLength={minLength}
    name={name}
    placeholder={placeholder}
    required={required}
    type="password"
    validationMessage={validationMessage}
  />
}

export default PasswordField
