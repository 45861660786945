import React, { useState } from 'react'
import classNames from 'classnames'

import * as C from 'components'
import { Money as MoneyHelper } from 'data'
import { useFormField } from 'hooks'

import './index.css'
import BaseLayout from 'blocks/layout/base-layout';

type Design = 'v1' | 'v2'

const StyleGuide: React.FC = () => {
  const [design, setDesign] = useState<Design>('v1')
  const text = useFormField('')
  const email = useFormField('')
  const phone = useFormField('')
  const textarea = useFormField('')
  const amount = useFormField(MoneyHelper.amountWithCurrency(2500, 'USD'))

  const amounts = {
    noMoney: MoneyHelper.zero,
    cad: MoneyHelper.amountWithCurrency(1234567890, 'CAD'),
    jpy: MoneyHelper.amountWithCurrency(5, 'JPY'),
    test: MoneyHelper.amountWithCurrency(10000, 'XTS'),
    inr: MoneyHelper.amountWithCurrency(100000000000, 'INR'),
    idr: MoneyHelper.amountWithCurrency(100000000000, 'IDR'),
    gbp: MoneyHelper.amountWithCurrency(350, 'GBP'),
    usd: MoneyHelper.amountWithCurrency(350, 'USD'),
  }
  const locales = [
    'en-US',
    'en-GB',
    'fr-FR',
    'hi-IN',
    'bo-IN',
    'ar-SA',
    'id-ID',
  ]

  const onClickNoOp = (e: React.MouseEvent) => {
  }

  const [debug, setDebug] = useState(false)

  return (
    <BaseLayout>
      <C.Section>
        <label>
          <input
            type="checkbox"
            checked={debug}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDebug(e.target.checked)}
          />
          Debug Mode
        </label>

        <hr />

        <C.Subhead>Design Style</C.Subhead>
        <label>
          V1
          <input
            checked={design === 'v1'}
            name="design"
            onClick={() => setDesign('v1')}
            type="radio"
            value="v1"
          />
        </label>

        <br />

        <label>
          V2
          <input
            checked={design === 'v2'}
            name="design"
            onClick={() => setDesign('v2')}
            type="radio"
            value="v2"
          />
        </label>
      </C.Section>

      <C.Section id={debug ? 'StyleGuideDebug' : 'StyleGuide'}>

        <C.Heading>Heading</C.Heading>

        <C.P>`P`aragraph one</C.P>
        <C.P>`P`aragraph two</C.P>
        <C.Subhead>Subhead</C.Subhead>
        <C.P>`P`aragraph three</C.P>
        <C.P centered>Centered `P`aragraph</C.P>

        <h3>Links and Buttons</h3>
        <C.Anchor url="https://example.com">Anchor</C.Anchor>
        <br />
        <C.AnchorButton onClick={onClickNoOp}>AnchorButton</C.AnchorButton>
        <br />
        <C.Link to="">Link</C.Link>
        <br />
        <C.Button type="button" onClick={onClickNoOp} text="Button type=button" />
        <C.Link to="#" button>Link button</C.Link>

        <h3>Money</h3>
        {locales.map(l => {
          return (
            <React.Fragment key={l}>
            <h4>{l}</h4>
            {Object.entries(amounts).map(([key, value]) => (
              <React.Fragment  key={`${l}-${key}`}>
              <C.Money amount={value} locale={l} />
              <br />
              </React.Fragment>
            ))}
            </React.Fragment>
        )})}

        <h3>Text</h3>
        Some <C.Highlighted>highlighted</C.Highlighted> text.
        <br />
        <h4>Time</h4>
        <C.Time unixtimeSeconds={1234567890} /><br />
        <C.Time unixtimeSeconds={9876543210} /><br />
        <C.Time unixtimeSeconds={1234567890} relative /><br />
        <C.Time unixtimeSeconds={9876543210} relative /><br />
        <h4>Name</h4>
        <C.Name name="Organizer Name" role="organizer" />
        <br />
        <C.Name name="Recipient Name" role="recipient" />


        <h3>Icons</h3>
        <C.Icon icon="checked" /> checked
        <br />
        <C.Icon icon="unchecked" /> unchecked
        <br />
        <C.Icon icon="email" /> email
        <br />
        <C.Icon icon="phone" /> phone
        <br />
        <C.Icon icon="lock" /> lock
        <br />
        <C.Icon icon="lock" inline /> lock inline
        <br />
        <C.Loader />

        <C.PaymentBreakdown breakdown={{
          chargedToPaymentMethod: amounts.usd,
          platformFee: amounts.jpy,
          processingCost: amounts.gbp,
          recipientAmount: amounts.test,
        }} />

        <C.List>
          <C.List.Item>List Item 1</C.List.Item>
          <C.List.Item>List Item 2</C.List.Item>
        </C.List>

        <C.List ordered>
          <C.List.Item>Order List Item 1</C.List.Item>
          <C.List.Item>Order List Item 2</C.List.Item>
        </C.List>

        <C.KeyValueList data={{
          Component: "KeyValueList",
          Row2: "Value two",
          Row3: "Value three",
          }} />


        <h3>Forms</h3>

        <C.TextField
          binding={text}
          placeholder="TextField"
        />

        <C.FormRow label="FormRow label">
          <C.TextField binding={text} placeholder="TextField inline" inline />
          <C.TextField binding={text} placeholder="TextField inline" inline />
        </C.FormRow>

        <C.TextArea
          binding={textarea}
          placeholder="TextArea"
        />

        <h3>Remote validation fields</h3>
        <C.FormRow>
          <C.EmailField binding={email} intent="update" inline />
          <C.P>{email.isValid ? '✅' : '❌' }</C.P>
        </C.FormRow>

        <C.FormRow>
          <C.PhoneNumberField binding={phone} intent="update" inline />
          <C.P>{phone.isValid ? '✅' : '❌' }</C.P>
        </C.FormRow>

        <C.MoneyInput binding={amount} />
        <C.PayPad binding={amount} />
        <C.Money amount={amount.value} />

      </C.Section>

      <C.Section
        footerLeading="Footer leading"
        footerTrailing="Footer trailing"
        headerLeading="Header Leading"
        headerTrailing="Header Trailing"
      >
        &lt;Section&gt; body
      </C.Section>
    </BaseLayout>
  )
}

export default StyleGuide
