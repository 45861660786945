import React from 'react'

import { TextField } from 'components'

interface Props extends FormFieldProps<string> {
  intent: 'billing' | 'kyc'
}
const PostalCodeField: React.FC<Props> = ({
  binding,
  inline,
  intent,
  name,
  required,
}) => {
  let placeholder
  switch (intent) {
    case 'billing':
      placeholder = 'Billing ZIP Code'
    break
    case 'kyc':
      placeholder = 'ZIP Code'
    break
  }
  return (
    <TextField
      autoComplete="postal-code"
      binding={binding}
      inline={inline}
      // This is WRONG and tied to US ZIP codes. But for now it's easiest
      inputMode="numeric"
      maxLength={5}
      name={name}
      pattern="[0-9]{5}"
      placeholder={placeholder}
      required={required}
    />
  )
}

export default PostalCodeField
