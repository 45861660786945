import React from 'react';
import classNames from 'classnames';

import './index.css';

/**
 * Note: this is so named (rather than e.g. Link) to avoid conflicts with
 * react-router-dom, which is for SPA navigation. This may change in future.
 */
interface Props {
    children: Children
    openInNewTab?: boolean
    trusted?: boolean
    url: string
    classes?: string
    onClick?: React.MouseEventHandler<HTMLAnchorElement>
}
const Anchor: React.FC<Props> = ({ children, url, classes = '', openInNewTab = false, trusted = false, onClick = undefined }) => {
    const target = openInNewTab ? '_blank' : '';
    const aClasses = classNames('Anchor');
    const rel = classNames('noopener', { noreferrer: !trusted });
    return (
        <a href={url} onClick={onClick} className={`${aClasses} ${classes}`} target={target} rel={rel}>
            {children}
        </a>
    );
};

export default Anchor;
