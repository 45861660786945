import React, { useEffect, useState } from 'react'

interface Props {
  intervalMs: number
  loop?: boolean
  textList: string[]
}
const RotatingText: React.FC<Props> = ({ textList, intervalMs, loop }) => {
  const maxIndex = textList.length - 1
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((current) => {
        if (current < maxIndex) {
          return current + 1
        }
        if (loop) {
          return 0
        }
        // This may be illegal but seems to work (??)
        clearInterval(timer)
        return maxIndex
      })
    }, intervalMs)

    return () => {
      clearInterval(timer)
    }
  }, [intervalMs, loop, maxIndex])

  return <>{textList[index]}</>
}

export default RotatingText
