import {
  Button,
  Form,
  Heading,
  P,
  PasswordField,
  Section,
} from 'components'
import { NavigationTarget } from 'data'
import { readError } from 'helpers'
import { useAppWideState, useFormField, useRedirect } from 'hooks'
import { builder } from 'routes'

import { postData } from 'helpers/post-data'
import BaseLayout from 'blocks/layout/base-layout'

const ResetPassword: React.FC = () => {
  const token = window.location.hash.substring(1)
  const { showError, showMessage } = useAppWideState()
  const newPassword = useFormField('')
  const redirect = useRedirect()
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      const result = await postData('/api/auth/password/reset', { token, newPassword })
      // throws on error?
      showMessage('Password changed! Please sign in.')
      redirect(new NavigationTarget({
        inPlace: true,
        url: builder.site.signIn,
      }))
    } catch (error) {
      showError(readError(error, 'We could not set your new password. Please try again.'))
    }
  }
  return (
    <BaseLayout>
      <Section>
        <Form onSubmit={onSubmit}>
          <Heading>Reset your password</Heading>
          <PasswordField binding={newPassword} required type="new" />
          <P>Passwords must be at least 8 characters long, and contain a number and symbol.</P>
          <Button type="submit" text="Submit" disabled={!newPassword.isValid} />
        </Form>
      </Section>
    </BaseLayout>
  )
}

export default ResetPassword
