import React from 'react'

/**
 * The Tab component is meant to be used as a direct child of Tabs, where it
 * will perform state management to display only the selected tab.
 */
interface Props {
  id: string
  panel: React.ReactNode
  title: string
}
const Tab: React.FC<Props> = ({ id, panel, title }) => {
  return <>{panel}</>
}

export default Tab
// This is a one-off violation of style rules since the outer Tabs component
// needs to be aware of a Tab's properties in order to handle state management.
export type { Props as ITabProps }
