// @ts-ignore-next-line (no decls in package)
import confetti from 'canvas-confetti';

const showConfetti: Thunk<void> = () => {
  confetti({
    particleCount: 130,
    spread: 60,
    colors: ['#D6DEFF', '#9CB4FF', '#7D92E0'],
    scalar: 1.5,
    zIndex: 150,
  })
}

export default showConfetti
