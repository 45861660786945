import React from 'react'

const rtf = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' });

/**
 * This should probably be a third-party library. I took a quick skim and
 * didn't find anything that looked appealing (mostly due to lack of type
 * information).
 *
 * I'm sure this is super imperfect (especially in performance!), but ought to
 * get reasonably close as a start.
 */
const formatRelative = (from: Date): string => {
  const diffSeconds = Math.round((from.getTime() - Date.now()) / 1000)

  if (Math.abs(diffSeconds) < 60) {
    return rtf.format(diffSeconds, 'second')
  }
  const minutes = Math.round(diffSeconds / 60)
  if (Math.abs(minutes) < 60) {
    return rtf.format(minutes, 'minute')
  }
  const hours = Math.round(minutes / 60)
  if (Math.abs(hours) < 24) {
    return rtf.format(hours, 'hour')
  }
  const days = Math.round(hours / 24)
  const absDays = Math.abs(days)
  if (absDays >= 365) {
    return rtf.format(Math.round(days / 365), 'year')
  } else if (absDays >= 30) {
    return rtf.format(Math.round(days / 30), 'month')
  } else if (absDays >= 7) {
    return rtf.format(Math.round(days / 7), 'week')
  } else {
    return rtf.format(days, 'day')
  }
}

// This may be extended to allow additional controls for formatting
interface Props {
  relative?: boolean
  unixtimeSeconds: UnixtimeSeconds
}
const Time: React.FC<Props> = ({ relative, unixtimeSeconds }) => {
  const parsed = new Date(unixtimeSeconds * 1000)

  let display: string
  if (relative) {
    display = formatRelative(parsed)
  } else {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#options
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      year: 'numeric',
      day: 'numeric',
      // hour: 'numeric',
      // minute: 'numeric',
      // second: 'numeric',
      // timeZoneName: 'short',
    }

    parsed.toLocaleString()
    display = parsed.toLocaleString(undefined, options)
  }
  return (
      <time
        className="Time"
        dateTime={parsed.toISOString()}
        title={parsed.toLocaleString()}
      >{display}
      </time>
  )
}

export default Time
