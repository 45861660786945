import React from 'react'

import {
  Button,
  EmailField,
  Form,
  Heading,
  Link,
  NameField,
  P,
  PhoneNumberField,
  TextArea,
} from 'components'
import { builder } from 'routes'

interface Props {
  email: FormBinding<string>
  groupId: GroupId
  message: FormBinding<string>
  name: FormBinding<string>
  nextPage: React.FormEventHandler
  phone: FormBinding<string>
}
const InviteForm: React.FC<Props> = ({
  email,
  groupId,
  message,
  name,
  nextPage,
  phone,
}) => {
  const allFieldsAreValid = email.isValid
    && message.isValid
    && name.isValid
    && phone.isValid

  return (
    <div id="InviteRecipient">
      <Form onSubmit={nextPage}>
        <Heading>Invite Recipient</Heading>
        <P color="979797">Recipients receive money from the group to their bank account.</P>

        <NameField
          binding={name}
          intent="recipient"
          required
        />
        <EmailField
          binding={email}
          intent="inviteRecipient"
          required
        />
        <PhoneNumberField
          binding={phone}
          intent="inviteRecipient"
          required
        />

        <P color="979797">We'll invite this person to join the group as the Recipient.</P>

        <TextArea
          binding={message}
          placeholder="Write a short message to the Recipient so they know it's really you!"
          required
        />

        <Button type="submit" text="Next" disabled={!allFieldsAreValid} />

        <P centered>
          <Link to={builder.group.view(groupId)} minimal>Go Back</Link>
        </P>

      </Form>
    </div>
  )
}

export default InviteForm
