import React, { useState } from 'react'

import {
  LoaderHeart,
  Section,
  UserInfoForm,
} from 'components'
import { NavigationTarget } from 'data'
import { useAppWideState, useFormField, usePageFlow, useRedirect } from 'hooks'
import { builder, groupIdInUrl } from 'routes'

import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'
import { readError } from 'helpers'

import Landing from './Landing'

import './index.scss'

export type JoinData = PaymentFormResult & { groupId: GroupId }

enum Page {
  EnterPayment = '',
  EnterRegistration = 'register',
}

interface Props {
  groupId: GroupId
}
const MemberLanding: React.FC<Props> = ({ groupId }) => {
  const { isSignedIn, showError, signIn } = useAppWideState()
  const redirect = useRedirect()
  const [page, updatePage] = usePageFlow<Page>(Page.EnterPayment)

  const [isPaying, setIsPaying] = useState(false)

  const name = useFormField('')
  const email = useFormField('')
  const password = useFormField('')
  const phone = useFormField('')

  if (isPaying) {
    return <LoaderHeart intent="pay" />
  }

  if (page === Page.EnterPayment) {
    let onSubmit: (data: PaymentFormResult) => Promise<boolean>
    if (isSignedIn) {
      onSubmit = async (data: PaymentFormResult) => {
        setIsPaying(true)
        const { recipientAmount: amount, paymentMethodId } = data
        try {
          await postData('/api/subscription/create', { amount, groupId, token: paymentMethodId })
        } catch (error) {
          showError(readError(error, "Payment failed, please try again"))
          return false
        } finally {
          setIsPaying(false)
        }

        redirect(new NavigationTarget({
          url: builder.group.waitingForApproval(groupId),
        }))
        return true
      }
    } else {
      onSubmit = async (data: PaymentFormResult) => {
        const {
          payerName,
          payerEmail,
          payerPhone,
          recipientAmount: amount,
          paymentMethodId,
        } = data
        setIsPaying(true)

        try {
          const regResult = await postData('/api/auth/register/password', {
            name: payerName,
            phone: payerPhone,
            email: payerEmail,
            password: null,
          })
          const token = regResult.data.token
          signIn(token)
        } catch (error) {
          showError(readError(error, 'Registration failed, please try again'))
          setIsPaying(false)
          return false
        }

        try {
          await postData('/api/subscription/create', { amount, groupId, token: paymentMethodId })
          // Prep transition to next page
          updatePage(Page.EnterRegistration)
          name.setValue(payerName ?? '')
          email.setValue(payerEmail ?? '')
          phone.setValue(payerPhone ?? '')
          return true
        } catch (error) {
          showError(readError(error, "Payment failed, please try again"))
          return false
        } finally {
          // All done, remove overlay
          setIsPaying(false)
        }
      }
    }

    return (
      <Landing
        groupId={groupId}
        onSubmit={onSubmit}
      />
    )
  } else if (page === Page.EnterRegistration) {
    postData('/api/analytics/track', { funnel: "MEMBER_JOIN", event: 'APP_MJ_ACCOUNT_CREATE', url: window.location.href, groupId })
    const onSignUpFormSubmit = async () => {
      await postData('/api/user/finishRegistration', { phone, name, email, password })
      redirect(new NavigationTarget({
        url: builder.group.waitingForApproval(groupId),
      }))
    }

    return (
      <Section>
        <UserInfoForm
          email={email}
          intent="update"
          name={name}
          password={password}
          phone={phone}
          submit={onSignUpFormSubmit}
        />
      </Section>
    )
  } else {
    throw new Error('Invalid page state')
  }
}

// Extract route info to component property
const Wrapper: React.FC<Props> = ({ groupId }) => {
  return (
    <BaseLayout hasSections>
      <MemberLanding groupId={groupId} />
    </BaseLayout>
  )
}
export default groupIdInUrl(Wrapper)
