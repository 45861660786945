import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  Heading,
  Link,
  P,
} from 'components'

interface Props {
  destination?: string
}
const ThankYou: React.FC<Props> = ({ destination }) => {
  const navigate = useNavigate()
  let button: React.ReactNode
  if (destination) {
    button = <Link to={destination} button>Got it!</Link>
  } else {
    button = <Button onClick={() => navigate(-1)} text="Got it!" type="button" />
  }
  return (
    <>
      <Heading>Thank you!</Heading>
      <P>Your message has been sent to our team.</P>
      <P>We'll do our best to get back to you within 24 hours or less!</P>
      {button}
    </>
  )
}

export default ThankYou
