import React from 'react'
import { useParams } from 'react-router-dom'

/**
 * This creates a HoC that reads the confirmCode out of the URL (via react-router)
 * and provides it to the component.
 */

interface TakesConfirmCode {
  confirmCode: string 
}

const confirmEmailInUrl = (Component: React.FC<TakesConfirmCode>) => {
  return () => {
    const { confirmCode } = useParams()
    if (!confirmCode) {
      throw new Error('Invalid Page')
    }
    return (
      <Component confirmCode={confirmCode} />
    )
  }
}

export default confirmEmailInUrl 
