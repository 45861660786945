import React from 'react'

import BaseLayout from 'blocks/layout/base-layout'

import { useApiQuery } from 'hooks'
import { groupIdInUrl, mustBeSignedIn } from 'routes'

import ManageActive from './ManageActive'
import ManageInactive from './ManageInactive'

interface Props {
  groupId: GroupId
}
const ChangeSubscription: React.FC<Props> = ({ groupId }) => {

  const sub = useApiQuery('/api/group/myActiveSubscription', { groupId })
  if (!sub) {
    // Not loaded; "you don't have one" handled below.
    return null
  }

  return (
    <BaseLayout showNavigation showNavigationBackButton>
      <div id="ChangeSubscription">
        {sub.id
          ? <ManageActive groupId={groupId} subscriptionId={sub.id} />
          : <ManageInactive groupId={groupId} />
        }
      </div>
    </BaseLayout>
  )
}

export default mustBeSignedIn(groupIdInUrl(ChangeSubscription))
