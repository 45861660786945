import React from 'react'

import { Link, List, P } from 'components'
import { useApiQuery } from 'hooks'
import { builder } from 'routes'

const CreateGroupBanner: React.FC = () => {
  const myPermissions = useApiQuery('/api/user/permissions', undefined)

  if (!myPermissions || !myPermissions.canOrganizeAdditionalGroups) {
    return null
  }

  return (
    <List.Item>
      <div id="CreateGroupBanner">
        <P><strong>Help another person?</strong></P>
        <Link to={builder.group.create}>
          Start a new group now
        </Link>
      </div>
    </List.Item>
  )
}

export default CreateGroupBanner
