import React from 'react'

import animation from './animation.svg'
import './index.css'

const Loader : React.FC = () => (
  <img
    alt=""
    className="Loader"
    src={animation}
  />
)

export default Loader
