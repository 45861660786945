import React, { useState, useEffect } from 'react'

import {
  Form,
  Highlighted,
  MoneyInput,
  P,
  PhoneNumberField,
  PaymentBreakdown,
  Subhead,
  SignInLink,
  TOSLinks,
  TextField
} from 'components'
import { Money } from 'data'
import { useFormField, useAppWideState } from 'hooks'

import { postData } from 'helpers/post-data'

import ModalPaymentForm from './ModalPaymentForm'
import type { PayerAndPaymentMethodData } from './ModalPaymentForm'

import './index.scss'

interface Props {
  above?: React.ReactNode
  buttonText: string
  collectPayerEmail: boolean
  collectPayerName: boolean
  collectPayerPhone: boolean
  currency: Currency
  disabled?: boolean
  initialAmount?: Money
  maxAmount: Money
  minAmount: Money
  onSubmit: (arg0: PaymentFormResult) => Promise<boolean>
  title: string
}
const PaymentForm: React.FC<Props> = ({
  above,
  buttonText,
  collectPayerEmail,
  collectPayerName,
  collectPayerPhone,
  currency,
  disabled,
  initialAmount,
  maxAmount,
  minAmount,
  onSubmit,
  title,
}) => {
  const zeroMoney = Money.zeroWithCurrency(currency)
  const amount = useFormField(initialAmount || zeroMoney)
  const [isAmountCalculating, setIsAmountCalculating] = useState(false)
  const [breakdown, setBreakdown] = useState(Money.createBreakdown(currency))

  // When amount changes, have the backend give us the latest calculation
  useEffect(() => {
    // This is non-obvious, but serves as a debouncer of sorts: when we start
    // the effect, set a flag. When the effect unmounts (read: amount.value
    // changes), set it to false. When the response arrives ASYNC, check if
    // it's still true - that means the amount hasn't changed since we started
    // the call, and is still the correct value.
    let mounted = true

    const getFromBackend = async () => {
      const backendResponse = await postData('/api/fees/calculate', { amount: amount.value })
      const breakdown = backendResponse.data as Api.Breakdown

      if (mounted) {
        // Note: trying to check breakdown.recipientAmount == amount won't work
        // due to the order of react state bindings. Already tried it.
        setBreakdown(breakdown)
      }
      // Implicit: ignore the response if unmounted - it's a stale (and
      // probably out of order) value.
      setIsAmountCalculating(false)
    }

    setIsAmountCalculating(true)
    getFromBackend()

    return () => {
      mounted = false
    }
  }, [amount.value])

    const disableForm = disabled
    || !amount.isValid


  // Merge the amount from this section of the form into the upstream
  // callback and run it.
  const onPaymentSubmit = async (data: PayerAndPaymentMethodData): Promise<boolean> => await onSubmit({
    ...data,
    recipientAmount: amount.value
  })

  // FIXME: Form.onSubmit should work too, especially for manual cc data
  return (
    <Form onSubmit={() => {}} classes="donate-form creditCard">
      {above}

      <MoneyInput binding={amount} min={minAmount} max={maxAmount} required />
      <P classes="mg-top--sm mg-btm--2xl"><Highlighted>100%</Highlighted> goes to the recipient because group members like you cover all fees.</P>
     
      <ModalPaymentForm
        breakdown={breakdown}
        buttonText={buttonText}
        collectPayerEmail={collectPayerEmail}
        collectPayerName={collectPayerName}
        collectPayerPhone={collectPayerPhone}
        disabled={disableForm}
        onPaymentSubmit={onPaymentSubmit}
      />
            
      <TOSLinks/>   
    </Form>
  )
}

export default PaymentForm
