import React from 'react'
import { useParams } from 'react-router-dom'

/**
 * This creates a HoC that reads the GroupId out of the URL (via react-router)
 * and provides it to the component.
 */

interface TakesGroupId {
  groupId: GroupId
}

const groupIdInUrl = (Component: React.FC<TakesGroupId>) => {
  return () => {
    const { groupId } = useParams()
    if (!groupId) {
      throw new Error('Invalid Page')
    }
    return (
      <Component groupId={groupId as GroupId} />
    )
  }
}

export default groupIdInUrl
