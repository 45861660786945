import { API_HOST } from 'config'

import { appStateForNetworkCallsOnlyUglyHack } from 'contexts/appWideState'

// import { datadogLogs } from '@datadog/browser-logs'

// This mimics the only part of Axios we used/inherited. See below.
interface WrappedResponse {
  data: any
}

/**
 * Intent to deprecate: this was originally a crude Axios wrapper, which led to
 * inconsistent error handling and tracking. The goal is to remove this
 * outright in favor of tooling more designed to fit our APIs, which can
 * improve type safety and result in more consistent error handling (including
 * logging and UX).
 */
export const postData = async (
  url: string,
  payload: DictOf<any> | undefined = undefined,
): Promise<WrappedResponse> => {
  const api = new Api()
  const httpResponse = await api.post(url, payload)
  if (httpResponse.status === 204) {
    return { data: undefined }
  }
  const contentType = httpResponse.headers.get('Content-type')
  if (contentType !== 'application/json') {
    console.error(`Got back non-JSON response (${contentType}) from server`, httpResponse)
    throw new Error('Unexpected response from server')
  }
  const decoded = await httpResponse.json()

  if (!httpResponse.ok) {
    throw new Error(decoded['message'] || 'There was an error processing your request');
  }

  // wrap to match old axios format
  return { data: decoded }
}

/**
 * Conceptual replacement for above.
 */
class Api {
  private apiKey: string | null
  private host: string

  constructor(apiKey: string | null = null) {
    this.host = API_HOST
    this.apiKey = apiKey || appStateForNetworkCallsOnlyUglyHack.authToken
  }

  public post = async (path: string, body: DictOf<any> | undefined): Promise<Response> => {

    const headers = new Headers({
      Accept: 'application/json',
      'Content-type': 'application/json',
    })
    if (this.apiKey !== null) {
      headers.set('Authorization', `Bearer ${this.apiKey}`)
    }

    const request = new Request(this.host + path, {
      body: JSON.stringify(body),
      headers,
      method: 'POST',
    })

    const response = await fetch(request)
    // what about 3xx? ok is only true for 200-299. Our API should generally
    // not serve them, but who knows.
    if (!response.ok) {
      // Read response body for logging without disrupting downstream reads
      // const responseCopy = response.clone()
      // let responseBody = undefined
      // try {
      //   responseBody = await responseCopy.json()
      // } catch {
      //   // not json response, figure this out later
      // }

      // TODO: figure out how to better integrate this with Datadog's tooling
      // https://docs.datadoghq.com/logs/log_configuration/attributes_naming_convention/
      // datadogLogs.logger.error('Error response from server', {
      //   http: {
      //     // standard
      //     url: request.url,
      //     status_code: response.status,
      //     method: request.method,
      //     // nonstandard
      //     body,
      //     responseBody,
      //   }
      // })
      console.error('Received error response from the server', request, response)
    }
    return response
  }
}


