import React, { useEffect } from 'react'

import {
  AnchorButton,
  Link,
  Heading,
  P,
} from 'components'
import { timings } from 'data'
import { pluralize, showConfetti } from 'helpers'
import { builder } from 'routes'


interface Props {
  approvedCount: number
  backToSelectionScreen: React.MouseEventHandler
  groupId: GroupId
  pendingCount: number
}
const AfterApprovedPage: React.FC<Props> = ({
  approvedCount,
  backToSelectionScreen,
  groupId,
  pendingCount,
}) => {
  useEffect(showConfetti, [])

  return (
    <>
      <Heading>Thanks!</Heading>

      <P>
        You've allowed {approvedCount} new {pluralize('member', approvedCount)} into your group.
      </P>

      {pendingCount > 0 && (<>
        <P>Any pending members that are not allowed into your group with {timings.refundUnapprovedMember} will be refunded.</P>
        <P>You currently have <strong>{pendingCount}</strong> additional pending {pluralize('member', pendingCount)}.</P>
      </>)}

      <Link to={builder.group.view(groupId)} button>Go to Group</Link>

      <P centered>
        <AnchorButton onClick={backToSelectionScreen}>
          Back to Pending Members
        </AnchorButton>
      </P>
    </>
  )
}

export default AfterApprovedPage
