import React from 'react'
import classNames from 'classnames'

import './index.scss'


interface Props {
  text: string | null
  classes?: string
}
/**
 * Component which applies extremely limited autoformatting to user-provided
 * text.
 *
 * Currently limited to whitespace controls; could expand to auto-linking or (a
 * subset of) Markdowns support in the future.
 */
const UserText: React.FC<Props> = ({ text, classes }) => (
  <div className={classNames('UserText', classes)}>
    {text}
  </div>
)

export default UserText
