import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  Button,
  Heading,
  Highlighted,
  Link,
  List,
  Loader,
  Money,
  Name,
  OverflowableMessage,
  P,
  SignInLink,
  Subhead,
  TOSLinks,
} from 'components'
import { Money as MoneyHelper, NavigationTarget } from 'data'
import { filter, pluralize, reduce } from 'helpers'
import { useApiQuery, useAppWideState, useRedirect } from 'hooks'
import { builder } from 'routes'

interface Props {
  groupId: GroupId
  nextPage: AsyncThunk<void>
}
const Landing: React.FC<Props> = ({ groupId, nextPage }) => {
  const { isSignedIn } = useAppWideState()
  const redirect = useRedirect()
  const [queryString] = useSearchParams()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const token = queryString.get('token') ?? ''

  const group = useApiQuery('/api/group/entity', { groupId })
  const onboarding = useApiQuery('/api/group/recipientOnboarding', { groupId, token })

  // Wrap the handler since signed out is an instant page transition but
  // signed-in is an API call
  const onClick = async () => {
    setIsSubmitting(true)
    await nextPage()
    setIsSubmitting(false)
  }

  useEffect(() => {
    if (onboarding && !onboarding.isAvailable && !isSubmitting) {
      redirect(new NavigationTarget({
        url: builder.group.view(groupId),
      }))
    }
  }, [onboarding, groupId, redirect, isSubmitting])

  if (!group || !onboarding || !onboarding.isAvailable) {
    return <Loader />
  }

  const members = onboarding.memberships
  const totalRecurring = members
    .map(m => m.amount)
    .filter(filter.notEmpty) // This SHOULD have no effect but makes type handling much easier
    .reduce(reduce.money.sum, MoneyHelper.zeroWithCurrency(group.defaultCurrency))

  return (
    <div id="RecipientLandingPage">
      <Heading>Please claim your money.</Heading>

      <P classes="--gray-txt"><Name name={group.organizerFullName} role="organizer" /> has invited you to claim monthly payments on AutoGive - the private and monthly way to give together.</P>
      <P classes="--gray-txt"><Name name={group.organizerFullName} role="organizer" /> says...</P>
      <OverflowableMessage message={group.publicMessage ? group.publicMessage : onboarding.message} />

      <Subhead>Group Details</Subhead>
      {filter.money.nonZero(totalRecurring) && (
      <P>
        Recipient: <Name name={group.recipientFullName} role="recipient" /> collects <Money amount={totalRecurring} highlighted /> per month from <strong>{members.length}</strong> group {pluralize('member', members.length)}:
      </P>
      )}

      <List>
        {members.map(member => (
          <List.Item key={member.id}>
            <Money amount={member.amount!} highlighted />
            &nbsp;&ndash;&nbsp;
            {member.memberFullName}
          </List.Item>
        ))}
      </List>

      <P>Each week you will receive a deposit. All deposits each month add up to the amount above.</P>
      <P>On the secure steps that follow, you will be asked to provide personal and banking info.</P>
      <P>You will need to provide the bank account where you would like your payments deposited.</P>
      <P>This information is most easily <strong>found on a check</strong>, or within your online banking profile.</P>
      <P>After providing these details, you will have access to the group collecting money on your behalf, see group members and post a message to others.</P>
      <P>
        <Highlighted>100%</Highlighted> of the amount above will be deposited into your bank account each month. There are <strong>no fees</strong> to receive money.
      </P>
      <P>Questions? Simply <Link to={builder.site.contact}>contact us</Link>.</P>

      <TOSLinks />

      <Subhead>Let's go...</Subhead>

      <Button text="Next" type="button" onClick={onClick} disabled={isSubmitting} />

      {!isSignedIn &&
      <P centered>
        Already have an account?
        <br /><SignInLink />
      </P>
      }
    </div>
  )
}

export default Landing
