import React from 'react'

import { FallbackProps } from 'react-error-boundary'

import BaseLayout from 'blocks/layout/base-layout'

import { AnchorButton, Heading, P } from 'components'
import { NavigationTarget } from 'data'
import { useRedirect } from 'hooks'
import { builder } from 'routes'

const Error: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  // Additional logging to 3P services? Can they hook console.error?
  console.error(error)
  const redirect = useRedirect()
  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    resetErrorBoundary()
    redirect(new NavigationTarget({
      url: builder.site.bugReport,
    }))
  }
  return (
    <BaseLayout>
      <div id="Error">
        <Heading>Something went wrong :(</Heading>
        <P>
          Sorry about that 💔
          But don't worry! Our team has been alerted and will look into it.
        </P>
        <P>In the meantime, feel free to <AnchorButton onClick={onClick}>contact our awesome support team</AnchorButton>.</P>
      </div>
    </BaseLayout>
  )
}

export default Error
