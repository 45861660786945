import React from 'react'

import BaseLayout from 'blocks/layout/base-layout';

import './index.css'

import {
  Heading,
  KeyValueList,
  List,
  Loader,
  Money,
  Subhead,
  Time,
} from 'components'
import { Money as MoneyHelper } from 'data'
import { reduce, sort } from 'helpers'
import { useApiQuery, useEntity } from 'hooks'
import { groupIdInUrl, mustBeSignedIn } from 'routes'

interface Props {
  groupId: GroupId
}
const GroupPaymentHistory: React.FC<Props> = ({ groupId }) => {
  const payments = useApiQuery('/api/payment/group', { groupId })
  const group = useApiQuery('/api/group/entity', { groupId })

  if (!payments || !group) {
    return <Loader />
  }

  const approvedMembersPayments = payments.filter(payment => !payment.isWaitingForApproval)

  const allTimeTotal = approvedMembersPayments
    .reduce(reduce.hasAmount.sum, MoneyHelper.zeroWithCurrency(group.defaultCurrency))

    return (<BaseLayout showNavigationBackButton showNavigation>
    <div id="GroupPaymentHistory">
      <Heading>Payment History</Heading>
      <KeyValueList data={{
        Group: group.name ? group.name : group.recipientFullName,
        From: 'All Group Members',
        Payments: approvedMembersPayments.length,
        'Lifetime Total': <Money amount={allTimeTotal} highlighted />
        }}
      />

      <Subhead>All Payments</Subhead>
      <List ordered>
        {approvedMembersPayments.sort(sort.createdNewestFirst).map(payment => (
          <PaymentRow key={payment.id} payment={payment} />
        ))}
      </List>
    </div>
  </BaseLayout>)
}

interface PRProps {
  payment: Api.Entity.Payment
}
const PaymentRow: React.FC<PRProps> = ({ payment }) => {
  const user = useEntity('user', payment.userId)
  return (
    <List.Item>
      <div className="time"><Time unixtimeSeconds={payment.createdAt} /></div>
      <div className="amountAndName">
        {payment.amount ? <Money amount={payment.amount} highlighted /> : <em>Amount private</em>}
        <> &ndash; </>
        {user?.name || <em>Private</em>}
      </div>
    </List.Item>
  )
}

export default mustBeSignedIn(groupIdInUrl(GroupPaymentHistory))
